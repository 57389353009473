import cn from 'classnames';
import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import BaseField, { BaseFieldProps } from '../BaseField';

import s from './TextareaField.module.scss';

type TextAreaProps = Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'>;

export type TextareaFieldProps = BaseFieldProps &
  TextAreaProps & {
    onChange?: (value: string) => void;
  };

const TextareaField = (
  { className, onFocus, onBlur, onChange, disabled, error, caption, filled, rows = 1, ...props }: TextareaFieldProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
): React.ReactElement => {
  const { opened: active, open: focus, close: blur } = useToggleState();

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>): void => {
      focus();
      onFocus?.(e);
    },
    [focus, onFocus],
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      blur();
      onBlur?.(e);
    },
    [blur, onBlur],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  return (
    <BaseField
      className={className}
      caption={caption}
      disabled={disabled}
      active={active}
      error={error}
      filled={filled}
      required={props.required}
    >
      <textarea
        ref={ref}
        className={cn('clean-input', s['textarea-field__textarea'])}
        rows={rows}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
    </BaseField>
  );
};

export default React.forwardRef(TextareaField) as typeof TextareaField;
