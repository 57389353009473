import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import { useRootStore } from '@/stores/global/RootStore';

import s from './CopyButton.module.scss';

type CopyButtonProps = {
  className?: string;
  textToCopy: string;
  alertText: string;
};

const CopyButton: React.FC<CopyButtonProps> = ({ className, textToCopy, alertText }) => {
  const { alertsStore } = useRootStore();

  const handleCopy = React.useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    alertsStore.addAlert({ message: alertText });
  }, [textToCopy]);

  return (
    <Button
      view={ButtonType.secondary}
      className={cn(className, s.copy__btn)}
      onClick={handleCopy}
      after={<Icon iconName="IconCopy" />}
    />
  );
};

export default observer(CopyButton);
