import cn from 'classnames';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ClickedProps, isAnchorProps, isButtonProps, isLinkProps, isNavLinkProps, ValueProp } from './types';

import s from './BaseClickable.module.scss';

export type BaseClickableProps<V extends ValueProp> = {
  block?: boolean;
  withHover?: boolean;
  children?: React.ReactNode;
  role?: React.AriaRole;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  className?: string;
} & React.AriaAttributes &
  ClickedProps<V>;

const BaseClickable = <V extends ValueProp>(
  {
    className,
    block,
    withHover,
    children = null,
    value,
    onClick,
    /** Не использовать вместе с onClick */
    onClickWithValue,
    stopPropagation,
    preventDefault,
    ...props
  }: BaseClickableProps<V>,
  ref: React.ForwardedRef<any>,
): React.ReactElement => {
  const baseProps = { ...props }; // Копируем, чтобы не мутировать props

  baseProps.element ??= 'button'; // element = button - по умолчанию (нельзя вытаскивать из props из-за typeguard'ов)

  const handlerClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      if (preventDefault) {
        e.preventDefault();
      }

      if (onClickWithValue && value !== undefined) {
        onClickWithValue(value);
      } else {
        onClick?.();
      }
    },
    [onClickWithValue, value, stopPropagation, preventDefault, onClick],
  );

  const commonClassName = cn(s.base, block && s.base_block, withHover && s['base_with-hover']);

  const commonProps = {
    ...(typeof className !== 'function' ? { className: cn(commonClassName, className) } : {}),
    onClick: handlerClick,
    ref,
  };

  if (isLinkProps(baseProps)) {
    return (
      <Link {...baseProps} {...commonProps}>
        {children}
      </Link>
    );
  }

  if (isNavLinkProps(baseProps)) {
    return (
      <NavLink {...baseProps} {...commonProps}>
        {children}
      </NavLink>
    );
  }

  if (isAnchorProps(baseProps)) {
    return (
      <a {...baseProps} {...commonProps}>
        {children}
      </a>
    );
  }

  if (isButtonProps(baseProps)) {
    baseProps.type ??= 'button';

    return (
      <button {...baseProps} {...commonProps}>
        {children}
      </button>
    );
  }

  const Element = baseProps.element;

  return (
    <Element {...baseProps} {...commonProps}>
      {children}
    </Element>
  );
};

export default React.forwardRef(BaseClickable) as typeof BaseClickable;
