import { AlertsStore, IAlertsStore } from '../AlertsStore';
import { DictionariesStore, IDictionariesStore } from '../DictionariesStore';
import { IntegrationsStore } from '../IntegrationsStore';
import { IIntegrationsStore } from '../IntegrationsStore/types';
import { IModalsStore, ModalsStore } from '../ModalsStore/ModalsStore';
import { IPushSubcribeStore, PushSubcribeStore } from '../PushSubcribeStore';
import { IUIStore, UIStore } from '../UIStore';
import { IUserStore, UserStore } from '../UserStore';

export interface IRootStore {
  userStore: IUserStore;
  dictionariesStore: IDictionariesStore;
  alertsStore: IAlertsStore;
  integrationsStore: IIntegrationsStore;
  uiStore: IUIStore;
  pushSubcribeStore: IPushSubcribeStore;
  modalsStore: IModalsStore;
}

export class RootStore implements IRootStore {
  readonly userStore: IUserStore = new UserStore({ rootStore: this });
  readonly dictionariesStore = new DictionariesStore();
  readonly alertsStore = new AlertsStore();
  readonly integrationsStore: IIntegrationsStore = new IntegrationsStore({ rootStore: this });
  readonly uiStore = new UIStore();
  readonly pushSubcribeStore: IPushSubcribeStore = new PushSubcribeStore({ rootStore: this });
  readonly modalsStore: IModalsStore = new ModalsStore();
}
