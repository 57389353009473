const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register('/sw.js');
        console.log(`Service worker registration successful with scope: ${registration.scope}`);
      } catch (error) {
        console.log(`Service worker registration failed with ${error}`);
      }
    });
  }
};

registerServiceWorker();
