import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { IRootStore } from '@/stores/global/RootStore';

import { IIntegrationsStore } from './types';

export type Params = {
  rootStore: IRootStore;
};

export class IntegrationsStore implements IIntegrationsStore {
  readonly learningPlatformStageModel = new LoadingStageModel();

  private readonly rootStore: IRootStore;

  private readonly learningPlatformLinkRequest = apiStore.createRequest<{ url: string }>({
    method: 'GET',
    url: apiUrls.integration.ispring,
  });

  constructor({ rootStore }: Params) {
    this.rootStore = rootStore;
  }

  goToLearningPlatform = async (): Promise<BaseResponse> => {
    if (this.learningPlatformStageModel.isLoading) {
      return { isError: true };
    }

    this.learningPlatformStageModel.loading();

    const windowReference = window.open();

    const response = await this.learningPlatformLinkRequest.call();

    if (response.isError || !response.data.url) {
      this.rootStore.alertsStore.addAlert({ message: 'Внутренняя ошибка сервера. Попробуйте позже' });
      this.learningPlatformStageModel.error();
      windowReference?.close();

      return { isError: true };
    }

    if (windowReference) {
      windowReference.location = response.data.url;
    }

    this.learningPlatformStageModel.success();
    this.rootStore.alertsStore.addAlert({
      message: 'Если не открылась новая вкладка, разрешите открытие всплывающих окон в браузере',
    });

    return { isError: false };
  };
}
