import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLocalStore } from '@kts-front/hooks';

import { LoadingStageModel } from '@/models/LoadingStageModel';

import { ObjectFit, PictureLoading } from './types';

import s from './Picture.module.scss';

export type PictureProps = {
  className?: string;
  src: string;
  alt: string;
  width: number;
  height: number;
  loading?: PictureLoading;
  objectFit?: ObjectFit;
};

const Picture: React.FC<PictureProps> = observer(
  ({ alt, src, className, objectFit = ObjectFit.contain, loading = PictureLoading.lazy, width, height }) => {
    const imageRef = React.useRef<HTMLImageElement>(null);
    const { store } = useLocalStore(() => new LoadingStageModel());

    React.useEffect(() => {
      const ref = imageRef.current;

      store.loading();

      if (!ref) {
        return;
      }

      const onLoad = () => {
        if (!ref) {
          return;
        }

        if (
          !src ||
          (ref.naturalWidth === 1 && ref.naturalHeight === 1) ||
          (ref.naturalWidth === 0 && ref.naturalHeight === 0)
        ) {
          store.error();

          return;
        }

        store.success();
      };

      const onError = () => {
        store.error();
      };

      if (ref.complete) {
        onLoad();

        return;
      }

      ref.addEventListener('load', onLoad);
      ref.addEventListener('error', onError);

      return () => {
        ref.removeEventListener('load', onLoad);
        ref.removeEventListener('error', onError);
      };
    }, [src, store]);

    return (
      <picture
        onError={store.error}
        className={cn(s.picture, store.isFinished && s.picture_loaded, store.isError && s.picture_error, className)}
      >
        <img
          width={width}
          height={height}
          className={cn(s.picture__src, s[`picture__src-${objectFit}`])}
          ref={imageRef}
          loading={loading}
          srcSet={src}
          alt={alt}
        />
      </picture>
    );
  },
);

export default React.memo(Picture);
