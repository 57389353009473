import { SortDirection } from '@/types/meta';
import { Nullable } from '@/types/values';

import { ProjectServer } from '../dictionaries';

export enum LeadStatus {
  unique = 'unique',
  wasUnique = 'wasunique',
  nonUnique = 'nonunique',
}

type ManagerServer = {
  id: number;
  first_name: string;
  last_name?: string;
};

type ClientServer = {
  first_name: string;
  last_name?: string;
  email: Nullable<string>;
  phone: string;
  comment: string;
};

type SubAgentServer =
  | {
      name: string;
      phone: string;
    }
  | {
      name: null;
      phone: null;
    };

export type CrmRejectionInfoServer = {
  lead_id: string;
  manager: Nullable<{
    name: Nullable<string>;
    phone: Nullable<string>;
  }>;
  agency: Nullable<{
    title: Nullable<string>;
  }>;
  message?: Nullable<string>;
  hold_date?: Nullable<string>;
};

export type LeadServer = {
  id: number;
  client: ClientServer;
  created_at: string;
  hold_date?: string;
  manager: ManagerServer;
  place_type: string;
  project: Pick<ProjectServer, 'id' | 'name'>;
  status: LeadStatus;
  sub_agent: SubAgentServer;
  crm_rejection_info: Nullable<CrmRejectionInfoServer>;
};

export type LeadListServer = {
  bids: LeadServer[];
  total: number;
};

export enum SortPayloadFields {
  created_at = 'created_at',
  hold_date = 'hold_date',
}

export type SortPayload = {
  field: SortPayloadFields;
  direction: Exclude<SortDirection, 'none'>;
};

export type HoldDatePayload = {
  from: Nullable<string>;
  to: Nullable<string>;
};

export type LeadListPayload = {
  pagination: {
    limit: number;
    offset: number;
  };
  filters: {
    manager_ids: Nullable<number[]>;
    statuses: Nullable<LeadStatus[]>;
    project_ids: Nullable<number[]>;
    place_types: Nullable<string[]>;
    hold_date: Nullable<HoldDatePayload>;
  };
  sort: Nullable<SortPayload[]>;
  search: Nullable<string>;
};

export type CreateLeadPayload = {
  client: ClientServer;
  manager: { id: number };
  project: { id: number };
  place_type: string;
  sub_agent: Nullable<{
    name: string;
    phone: string | null;
  }>;
  source_type: Nullable<string>;
};
