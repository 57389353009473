export type Alert = {
  id: string;
  content: string;
  kind: AlertKind;
};

export enum AlertKind {
  default = 'default',
  error = 'error',
}
