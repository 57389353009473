import cn from 'classnames';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@/components/Loader';
import { LoadingStage } from '@/types/meta';

import DialogStub, { DialogContentProps } from '../DialogStub';

import ScrollUpBottom from './ScrollUpBottom';

import s from './InfiniteList.module.scss';

type InfiniteListItemProps<T> = {
  item: T;
};

interface IItem {
  id: number;
}

type Props<T extends IItem> = {
  loadMore: () => void;
  hasMore: boolean;
  containerRef: React.RefObject<HTMLDivElement | null>;
  entities: T[];
  ItemComponent: React.ComponentType<InfiniteListItemProps<T>>;
  loadingStage: LoadingStage;
  hasSelectedFiltersOrSearch: boolean;
  refetch: () => void;
  resetFilters: () => void;
  emptyState: DialogContentProps;
};

const InfiniteList = <T extends IItem>({
  loadMore,
  hasMore,
  containerRef,
  entities,
  ItemComponent,
  hasSelectedFiltersOrSearch,
  loadingStage,
  refetch,
  resetFilters,
  emptyState,
}: Props<T>): React.ReactElement => {
  const getScrollParent = React.useCallback(() => containerRef.current, []);

  const isError = loadingStage === LoadingStage.error;
  const isLoading = loadingStage === LoadingStage.loading;
  const isEmpty = !entities.length && loadingStage === LoadingStage.success;

  if (isError) {
    return (
      <DialogStub
        text="Что-то пошло не так. Пожалуйста, попробуйте позже"
        buttonText="Попробовать еще раз"
        onClick={refetch}
      />
    );
  }

  if (isEmpty && hasSelectedFiltersOrSearch) {
    return (
      <DialogStub text="По вашему запросу ничего не найдено." buttonText="Сбросить фильтры" onClick={resetFilters} />
    );
  }

  if (isEmpty && !hasSelectedFiltersOrSearch) {
    return <DialogStub {...emptyState} withPlus />;
  }

  return (
    <>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
        getScrollParent={getScrollParent}
        initialLoad={false}
      >
        <>
          {entities.map((item) => (
            <ItemComponent key={item.id} item={item} />
          ))}
          {isLoading && <Loader className={cn(s.loader, !entities.length && s.loader_init)} />}
        </>
      </InfiniteScroll>
      <ScrollUpBottom containerRef={containerRef} />
    </>
  );
};

export default InfiniteList;
