import cn from 'classnames';
import * as React from 'react';

import Portal from '../Portal';
import { ModalVariant } from '../types';

import s from './Overlay.module.scss';

export type OverlayProps = React.PropsWithChildren<{
  onClose: VoidFunction;
  opened: boolean;
  variant: ModalVariant;
  ariaLabel: string;
  withBackdrop?: boolean;
}>;

const ANIMATION_TIME = 600;

const Overlay: React.FC<OverlayProps> = ({ children, opened, onClose, variant, ariaLabel, withBackdrop = true }) => {
  // Промежуточное состояние для анимации,
  // т.е. даем модальному окну возможность отрисоваться с начальными стилями,
  // а потом ререндерим и подсовываем новые стили для анимации
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (opened && !mounted) {
      setMounted(true);
    } else if (!opened && mounted) {
      setTimeout(() => {
        setMounted(false);
      }, ANIMATION_TIME);
    }
  }, [opened]);

  if (!opened && !mounted) {
    return null;
  }

  const visible = mounted && opened;

  return (
    <Portal>
      <div
        className={cn(s.container, s[`container_${variant}`])}
        role="dialog"
        aria-modal="true"
        aria-hidden={!visible}
        aria-label={ariaLabel}
      >
        <div
          className={cn(
            s.container_overlay,
            visible && s.container_overlay_visible,
            withBackdrop && s.container_overlay_backdrop,
          )}
          role="presentation"
          onClick={onClose}
        />
        <div
          className={cn(
            s.container_content,
            s[`container_content_${variant}`],
            visible && s[`container_content_${variant}_visible`],
          )}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Overlay;
