import cn from 'classnames';
import * as React from 'react';

import { CheckboxGridProps } from '../CheckboxGrid';
import Checkbox, { CheckboxSize, CheckboxType } from '../../Checkbox';

import s from './CustomCheckbox.module.scss';

type CustomCheckboxProps<V extends string | number> = {
  id: V;
  label: string;
} & Omit<CheckboxGridProps<V>, 'id' | 'options'>;

const CustomCheckbox = <V extends string | number>({
  className,
  color,
  id,
  label,
  onChange,
  type = CheckboxType.radio,
  value,
  ...props
}: CustomCheckboxProps<V>) => {
  const checked = id === value;

  const handleChange = React.useCallback(
    (_: boolean, value: V) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <Checkbox
      {...props}
      className={cn(s.checkbox, checked && s.checkbox_checked, className)}
      caption={label}
      captionBorderColor={color}
      checked={checked}
      onChange={handleChange}
      size={CheckboxSize.l}
      type={type}
      value={id}
    />
  );
};

export default CustomCheckbox;
