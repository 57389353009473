export const formatPhoneNumber = (phone: string | number) => {
  try {
    return phone
      .toString()
      .replace(/\D/g, '')
      .replace(/^(7|8)?(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');
  } catch (error) {
    return '';
  }
};
