import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import CheckboxGrid from '@/components/form/CheckboxGrid';
import { useCreateLeadFormStore } from '@/stores/local/CreateLeadFormStore';

import s from './ProjectAndPlaceType.module.scss';

type Props = {
  className?: string;
};

const ProjectAndPlaceType: React.FC<Props> = ({ className }) => {
  const { project, placeType, dictionaries, changeProject, expectedPlaceTypes } = useCreateLeadFormStore();
  const { projects } = dictionaries;

  return (
    <div className={cn(s.wrapper, className)}>
      <CheckboxGrid
        {...project.props}
        columns={projects.length > 2 ? 3 : 2}
        options={projects.items}
        onChange={changeProject}
      />
      <CheckboxGrid {...placeType.props} options={dictionaries.placeTypes.items} expectedValues={expectedPlaceTypes} />
    </div>
  );
};

export default observer(ProjectAndPlaceType);
