import { computed, makeObservable } from 'mobx';

import { Validator } from '@/types/validator';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export enum InputType {
  text = 'text',
  number = 'number',
  email = 'email',
  tel = 'tel',
}

type InputModelParams<V> = BaseFieldModelParams<V> & {
  type?: InputType;
  validatorsOnChange?: Validator<V>[];
};

export class InputModel<V = string, E extends HTMLElement = HTMLInputElement> extends BaseFieldModel<V, E> {
  readonly type: InputType;
  private _validatorsOnChange: Validator<V>[];

  constructor({ type = InputType.text, validatorsOnChange = [], ...params }: InputModelParams<V>) {
    super(params);

    makeObservable(this, {
      props: computed,
    });

    this.type = type;
    this._validatorsOnChange = validatorsOnChange;
  }

  change(value: V): void {
    if (this._validatorsOnChange.some((check) => check(value))) {
      return;
    }

    super.change(value);
    this.hasErrorModel.reset();
  }

  get props() {
    return {
      ref: this.ref,
      value: this._value,
      onChange: this.change,
      onBlur: this.validate,
      caption: this.caption,
      subcaption: this.subcaption,
      disabled: this.disabled,
      required: this.required,
      filled: !!this.value,
      error: this.error,
      isError: this.hasError,
      type: this.type,
    };
  }
}
