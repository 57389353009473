import * as React from 'react';

/**
 * Если нет интернета изначально (!), то анимация появления алерта не работает.
 * Эта проблема решается тем, что можно рендерить алерты чуть позже (100 мс), тогда анимация работает.
 */
export const useShowAlerts = (): boolean => {
  const [showAlerts, setShow] = React.useState(navigator.onLine ? true : false);

  React.useEffect(() => {
    if (navigator.onLine) {
      return;
    }

    setTimeout(() => setShow(true), 100);
  }, []);

  return showAlerts;
};
