import { ToggleModel } from '@/models/ToggleModel';

import { ILeadsStore, LeadModalStore } from './LeadModalStore';

export interface IModalsStore {
  /** Модалка списка уведомлений */
  notificationListModal: ToggleModel;
  /** Модалка с информацией о назначении встречи */
  meetingNoticeModal: ToggleModel;
  /** Модалка с детальной информацией о заявке */
  leadModalStore: ILeadsStore;
}

export class ModalsStore implements IModalsStore {
  readonly notificationListModal = new ToggleModel();
  readonly meetingNoticeModal = new ToggleModel();
  readonly leadModalStore = new LeadModalStore();
}
