import * as React from 'react';
import { RouterProvider } from 'react-router-dom';

import LoadingStub from '@/components/LoadingStub';
import router from '@/configs/routes/router';
import { RootStoreProvider } from '@/stores/global/RootStore';

const Root = (): React.ReactElement => {
  return (
    <RootStoreProvider>
      <React.Suspense fallback={<LoadingStub />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </RootStoreProvider>
  );
};

export default Root;
