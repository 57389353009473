import * as React from 'react';

import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { FormaEmployee } from '@/entities/formaEmployee';

import s from './Employee.module.scss';

type Props = {
  employee: FormaEmployee;
  className?: string;
};

const Employee: React.FC<Props> = ({ employee, className }) => {
  return (
    <div className={className}>
      <Typo view={TypoView.text} weight={TypoWeight.medium}>
        {employee.name}
      </Typo>
      <Typo className={s.position} view={TypoView.caption}>
        {employee.position}
      </Typo>
      <div className={s['link-container']}>
        <div className={s['link-title']}>Email:</div>
        <a className={s.link} target="_blank" href={`mailto:${employee.email}`}>
          {employee.email}
        </a>
      </div>
      <div className={s['link-container']}>
        <div className={s['link-title']}>Telegram:</div>
        <a className={s.link} target="_blank" href={`https://t.me/${employee.telegram}`}>
          @{employee.telegram}
        </a>
      </div>
    </div>
  );
};

export default Employee;
