import cn from 'classnames';
import * as React from 'react';

import Counter from '../Counter';

import s from './CountWrapper.module.scss';

type Props = React.PropsWithChildren<{
  count: number | null;
  className?: string;
  onClick?: () => void;
}>;

const CountWrapper: React.FC<Props> = ({ count, children, className, onClick }: Props) => {
  return (
    <div className={cn(s.wrapper, className)} onClick={onClick}>
      {children}
      {count !== null && count !== 0 && <Counter count={count} className={s.wrapper__counter} />}
    </div>
  );
};

export default CountWrapper;
