import { Month, setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const locale = {
  ...ru,
  localize: {
    ...ru.localize,
    month: (value: Month) => months[value],
  },
};

setDefaultOptions({
  weekStartsOn: 1,
  locale,
});