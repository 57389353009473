import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon from '@/components/Icon';
import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import s from './NotificationsSubscribeModal.module.scss';

const NotificationsSubscribeModal: React.FC = () => {
  const { pushSubcribeStore } = useRootStore();

  React.useEffect(() => {
    pushSubcribeStore.initSubscribe();
  }, [pushSubcribeStore.canSubscribe]);

  return (
    <Modal
      className={s.modal}
      variant={ModalVariant.Confirm}
      opened={pushSubcribeStore.modal.isOpen}
      onClose={pushSubcribeStore.modal.close}
      primaryButtonOnClick={pushSubcribeStore.trySubscribe}
      primaryButtonText="Подписаться"
      secondaryButtonOnClick={pushSubcribeStore.modal.close}
      secondaryButtonText="Позже"
      contentClassName={s.modal__content}
      buttonClassName={s.modal__btn}
      showCloseButton={false}
      ariaLabel="Чтобы получать уведомления по клиентам - подпишитесь"
      title={<Typo view={TypoView.h5}>Чтобы получать уведомления по клиентам - подпишитесь</Typo>}
    >
      <Icon iconName="IconNotificationsSubscribe" />
    </Modal>
  );
};

export default observer(NotificationsSubscribeModal);
