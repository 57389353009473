import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import { routerUrls } from '@/configs/routes';
import { useRootStore } from '@/stores/global/RootStore';

import Icon from '../Icon';
import PageTitle from '../PageTitle';
import Typo, { TypoView, TypoWeight } from '../Typo';
import DetailLeadModal from '../leads/DetailLeadModal';
import MeetingNoticeModal from '../leads/MeetingNoticeModal';

import Notifications from './Notifications';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ className }) => {
  const {
    userStore,
    modalsStore,
    uiStore: { isPhone },
  } = useRootStore();
  const navigate = useNavigate();

  const handleLogout = React.useCallback(async () => {
    const response = await userStore.logout();

    if (!response.isError) {
      navigate(routerUrls.root);
    }
  }, [userStore]);

  return (
    <div className={cn(s.header, className)}>
      <PageTitle />
      <div className={s['header__user-section']}>
        <Notifications className={s.header__notifications} />
        {!isPhone && (
          <Button className={s.header__name} element="button" view={ButtonType.secondary} size={ButtonSize.small}>
            <Typo view={TypoView.text} weight={TypoWeight.regular}>
              {userStore.user?.fullName || ''}
            </Typo>
          </Button>
        )}
        <Button
          className={s.header__exit}
          element="button"
          view={ButtonType.secondary}
          size={ButtonSize.small}
          onClick={handleLogout}
          after={<Icon iconName="IconExit" />}
          disabled={userStore.logoutStage.isLoading}
        >
          {!isPhone ? 'Выйти' : ''}
        </Button>
        <DetailLeadModal />
        <MeetingNoticeModal
          opened={modalsStore.meetingNoticeModal.isOpen}
          onClose={modalsStore.meetingNoticeModal.close}
        />
      </div>
    </div>
  );
};

export default observer(Header);
