import { isValidPhoneNumber } from 'react-phone-number-input';

import { Validator, ValidatorResult } from '@/types/validator';
import { DateRange } from '@/types/values';

const ERROR_REQUIRED_FIELD = 'Обязательное поле';
const ERROR_NUMBER = 'В поле должно быть число';
const ERROR_EMAIL = 'Введите корректный email';
const ERROR_PHONE_NUMBER = 'Введите корректный номер телефона';
const ERROR_MAX_LENGTH = 'Превышена максимальная длина';
const ERROR_MIN_LENGTH = 'Слишком короткое значение';
const ERROR_INN = 'Некорректное значение ИНН';
const ERROR_DATE_RANGE = 'Неверный диапазон дат';

export const validateNotEmpty =
  <T>(error: string = ERROR_REQUIRED_FIELD): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || !String(value).trim().length ? error : null;

export const validateMaxLength =
  <T>(maxLength: number, error: string = ERROR_MAX_LENGTH): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || String(value).trim().length > maxLength ? error : null;

export const validateMinLength =
  <T>(minLength: number, error: string = ERROR_MIN_LENGTH): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || String(value).trim().length < minLength ? error : null;

export const validateNumber =
  <T>(error: string = ERROR_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult =>
    /[^0-9]/.test(String(v)) ? error : null;

export const validateINN =
  <T>(error: string = ERROR_INN): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    /^(\d{10}|\d{12})$/.test(String(value)) ? null : error;

export const validateEmail =
  (error: string = ERROR_EMAIL): Validator<string> =>
  (v) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(String(v)) ? null : error;

export const validateNotEmptyEmail =
  (error: string = ERROR_EMAIL): Validator<string> =>
  (v) =>
    v.length === 0 || validateEmail(v) ? null : error;

export const validatePhoneNumber =
  <T>(error: string = ERROR_PHONE_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult => {
    return typeof v === 'string' && isValidPhoneNumber(v) ? null : error;
  };

export const validateNotEmptyPhoneNumber =
  <T>(error: string = ERROR_PHONE_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult => {
    return v === null || (typeof v === 'string' && (v.length === 0 || isValidPhoneNumber(v))) ? null : error;
  };

export const validateDateRange =
  (error: string = ERROR_DATE_RANGE): Validator<DateRange> =>
  ([from, to]) => {
    if (from && to && from > to) {
      return error;
    }

    return null;
  };
