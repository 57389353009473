import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Loader from '@/components/Loader';
import { DictionaryFields, DictionaryLoadFields } from '@/stores/global/DictionariesStore';
import { useRootStore } from '@/stores/global/RootStore';

import s from './withDictionaries.module.scss';

type Params<P> = {
  Component: React.ComponentType<P>;
  dictionaries: DictionaryFields[];
};

const mapDictioanryLoads: Record<DictionaryFields, DictionaryLoadFields> = {
  [DictionaryFields.employees]: DictionaryLoadFields.loadEmployees,
  [DictionaryFields.placeTypes]: DictionaryLoadFields.loadPlaceTypes,
  [DictionaryFields.projects]: DictionaryLoadFields.loadProjects,
  [DictionaryFields.sources]: DictionaryLoadFields.loadSources,
};

/* HOC для предварительной загрузки необходимых словарей */
export const withDictionaries = <P extends Record<string, unknown>>({ Component, dictionaries }: Params<P>) =>
  observer((props: P) => {
    const { dictionariesStore } = useRootStore();

    React.useEffect(() => {
      dictionaries.forEach((key) => {
        if (dictionariesStore[key].loadingStage.isNotStarted) {
          dictionariesStore[mapDictioanryLoads[key]]();
        }
      });
    }, [dictionaries]);

    const hasNeedDictionaries = dictionaries.every((key) => dictionariesStore[key].loadingStage.isFinished);

    if (!hasNeedDictionaries) {
      return (
        <div className={s.loading}>
          <Loader />
        </div>
      );
    }

    return <Component {...props} />;
  });
