import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';
import { ValidatorResult } from '@/types/validator';
import { Nullable, SelectOption } from '@/types/values';
import { withRequiredSymbol } from '@/utils/withRequiredSymbol';

import { CheckboxProps } from '../Checkbox';
import FieldError from '../FieldError';

import CustomCheckbox from './CustomCheckbox';

import s from './CheckboxGrid.module.scss';

export type CheckboxGridOption<V extends string | number> = SelectOption<V> & {
  color?: string; // Значение в HEX формате из api
};

export type CheckboxGridProps<V extends string | number> = {
  columns?: number;
  error?: ValidatorResult;
  expectedValues?: Set<V>;
  onChange?: (value: V) => void;
  options: CheckboxGridOption<V>[];
  value?: Nullable<V>;
} & Omit<CheckboxProps<V>, 'onChange' | 'value' | 'checked' | 'size' | 'captionBorderColor'>;

const CheckboxGrid = <V extends string | number = string>({
  className,
  caption,
  columns = 2,
  error,
  onBlur,
  options,
  required = false,
  ...props
}: CheckboxGridProps<V>) => {
  const { isPhone } = useRootStore().uiStore;
  const expectedValues = props.expectedValues;

  return (
    <div className={className}>
      {caption && (
        <Typo
          className={s.grid_caption}
          view={isPhone ? TypoView.h4 : TypoView.h3}
          element={isPhone ? TypoElement.h4 : TypoElement.h3}
        >
          {withRequiredSymbol(caption, required)}
        </Typo>
      )}
      <div
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
        className={cn(s.grid, error && s.grid_error)}
        onBlur={onBlur}
      >
        {options.map((option) => {
          const disabled = expectedValues && expectedValues.size > 0 && !expectedValues.has(option.id);

          if (disabled) {
            return null;
          }

          return <CustomCheckbox key={option.id} {...props} {...option} />;
        })}
      </div>
      <FieldError error={error} />
    </div>
  );
};

export default observer(CheckboxGrid);
