import { IBaseNotification, NotificationType } from '@/entities/notification';
import { FixationPeriodNotificationServer, OtherNotificationServer } from '@/entities/notification/server';

class BaseNotificationModel<T extends NotificationType, E> implements IBaseNotification<T, E> {
  readonly id: number;
  readonly title: string;
  readonly type: T;
  readonly text: string;
  readonly read: boolean;
  readonly createdDate: Date;
  readonly extra: E;

  constructor({ id, title, type, text, read, createdDate, extra }: IBaseNotification<T, E>) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.text = text;
    this.read = read;
    this.createdDate = new Date(createdDate);
    this.extra = extra;
  }
}

export class FixationPeriodNotificationModel extends BaseNotificationModel<
  NotificationType.fixationPeriod,
  { leadId: number }
> {
  static fromJson(raw: FixationPeriodNotificationServer): FixationPeriodNotificationModel {
    return new FixationPeriodNotificationModel({
      id: raw.id,
      title: raw.title,
      type: NotificationType.fixationPeriod,
      text: raw.text,
      read: raw.read,
      createdDate: new Date(raw.created_date),
      extra: { leadId: raw.extra.lead_id },
    });
  }
}

export class OtherNotificationModel extends BaseNotificationModel<NotificationType.other, null> {
  static fromJson(raw: OtherNotificationServer): OtherNotificationModel {
    return new OtherNotificationModel({
      id: raw.id,
      title: raw.title,
      type: NotificationType.other,
      text: raw.text,
      read: raw.read,
      createdDate: new Date(raw.created_date),
      extra: null,
    });
  }
}

export type NotificationModelType = FixationPeriodNotificationModel | OtherNotificationModel;
