import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import Typo, { TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import s from './BackNotificationButton.module.scss';

const BackNotificationButton: React.FC<{ onClose: VoidFunction }> = ({ onClose }) => {
  const {
    modalsStore: { notificationListModal },
  } = useRootStore();

  const handleBack = React.useCallback(() => {
    onClose();
    notificationListModal.open();
  }, []);

  return (
    <Button
      view={ButtonType.secondary}
      size={ButtonSize.custom}
      onClick={handleBack}
      className={s.button}
      after={
        <div className={s.button__content}>
          {<Icon iconName="IconArrowLeft" size={20} />}
          <Typo block view={TypoView.textSmall}>
            Уведомления
          </Typo>
        </div>
      }
    ></Button>
  );
};

export default observer(BackNotificationButton);
