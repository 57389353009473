import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@/components/IconButton';
import Typo, { TypoView } from '@/components/Typo';
import { routerUrls } from '@/configs/routes';

import s from './MobileTitle.module.scss';

type Params = {
  title: string;
};

const MobileTitle: React.FC<Params> = ({ title }) => {
  const navigate = useNavigate();

  const handleBack = React.useCallback((): void => {
    navigate(routerUrls.main.create());
  }, []);

  return (
    <div className={s.title}>
      <IconButton iconName="IconArrowBack" onClick={handleBack} className={s.title__btn} />
      <Typo className={s.title__text} view={TypoView.h3}>
        {title}
      </Typo>
    </div>
  );
};

export default MobileTitle;
