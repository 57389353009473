import * as Sentry from "@sentry/react";

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: "https://935fb4b90e404690aad63fd39ad90e70@sentry.pulse.kts.studio/345",
    integrations: [
      Sentry.browserTracingIntegration(),
    ]
  });
}