import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { LeadModalStoreOpenType } from '@/stores/global/ModalsStore/LeadModalStore';
import { useRootStore } from '@/stores/global/RootStore';
import { convertStringToNumber } from '@/utils/convertStringToNumber';

import BackNotificationButton from './BackNotificationButton';
import DetailLeadContent from './DetailLeadContent';

import s from './DetailLeadModal.module.scss';

const DetailLeadModal: React.FC = () => {
  const {
    modalsStore: { leadModalStore },
  } = useRootStore();

  const [queryParams] = useSearchParams();
  const leadIdParam = queryParams.get('leadId');

  React.useEffect(() => {
    const leadId = leadIdParam ? convertStringToNumber(leadIdParam) : null;

    if (!leadId) {
      return;
    }

    leadModalStore.open({ type: LeadModalStoreOpenType.id, value: leadId });
  }, [leadIdParam]);

  return (
    <Modal
      className={s.modal}
      contentClassName={s.modal__content}
      ariaLabel="Новый сотрудник"
      opened={leadModalStore.detailLeadModal.isOpen}
      withBackdrop
      variant={ModalVariant.RightWindow}
      onClose={leadModalStore.close}
      isScrollable={false}
    >
      {leadModalStore.isNotificationBack.value && <BackNotificationButton onClose={leadModalStore.close} />}
      <Typo block weight={TypoWeight.medium} view={TypoView.caption} uppercase={true} className={s.modal__title}>
        Детали заявки
      </Typo>
      <DetailLeadContent
        lead={leadModalStore.lead.value}
        isLoading={leadModalStore.loadingStage.isLoading}
        isError={leadModalStore.loadingStage.isError}
        refetch={leadModalStore.refetch}
      />
    </Modal>
  );
};

export default observer(DetailLeadModal);
