import { routerUrls } from '@/configs/routes';

export const PageTitles = {
  [routerUrls.main.mask]: 'Личный кабинет партнера',
  [routerUrls.leadList.create()]: 'Фиксация клиентов',
  [routerUrls.employeeList.mask]: 'Управление доступами',
};

export const MobilePageTitles = {
  [routerUrls.leadList.create()]: 'Заявки',
  [routerUrls.employeeList.mask]: 'Сотрудники',
};
