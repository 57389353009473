import { IconName } from '@/components/Icon';
import { ResultType } from '@/stores/local/CreateLeadFormStore';

type Props = {
  title: string;
  description: string;
  buttonText: string;
  iconName: IconName;
  iconHeight: number;
};

export const contentPropsConfig: Record<ResultType, Props> = {
  [ResultType.success]: {
    title: 'Заявка принята',
    description: 'Клиент уникален и закреплён.',
    buttonText: 'Отправить еще',
    iconName: 'IconBusiness',
    iconHeight: 300,
  },
  [ResultType.refused]: {
    title: 'Заявка отклонена',
    description: 'Клиент неуникален и не может быть закреплён.',
    buttonText: 'Отправить другую заявку',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
  [ResultType.refusedWithInfo]: {
    title: 'Заявка отклонена',
    description: 'К сожалению, клиент неуникален и не может быть закреплён.',
    buttonText: 'Отправить другую заявку',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
  [ResultType.refusedWithInfoList]: {
    title: 'Заявка отклонена',
    description: 'Клиент неуникален и не может быть закреплён.',
    buttonText: '',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
};
