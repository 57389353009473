import * as React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import { withAuth } from '@/hocs/withAuth';
import { withDictionaries } from '@/hocs/withDictionaries';
import App from '@/pages/App';
import { DICTIONARIES_FOR_LEADS } from '@/stores/global/DictionariesStore';

import { routerUrls } from './routerUrls';

/* Страницы с предзагруженными словарями */
const LeadListWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/LeadList')),
  dictionaries: DICTIONARIES_FOR_LEADS,
});

/* Страницы доступные только авторизованным пользователям */
const Main = withAuth({ Component: React.lazy(() => import('@/pages/Main')) });
const LeadList = withAuth({ Component: LeadListWithDictionaries });
const EmployeeList = withAuth({ Component: React.lazy(() => import('@/pages/EmployeeList')), onlyAdmin: true });

/* Страницы доступные только неавторизованным пользователям */
const Login = withAuth({ Component: React.lazy(() => import('@/pages/Login')), forAuthorizedUser: false });

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routerUrls.main.mask,
        element: <Main />,
      },
      {
        path: routerUrls.leadList.mask,
        element: <LeadList />,
      },
      {
        path: routerUrls.employeeList.mask,
        element: <EmployeeList />,
      },
      {
        index: true,
        element: <Login />,
      },
      {
        path: '*',
        element: <Navigate to={'/'} replace />,
      },
    ],
  },
]);

export default router;
