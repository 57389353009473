import * as React from 'react';

import Loader from '../Loader';

import s from './LoadingStub.module.scss';

const LoadingStub: React.FC = () => {
  return <Loader className={s['loading-stub']} />;
};

export default LoadingStub;
