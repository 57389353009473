import cn from 'classnames';
import * as React from 'react';

import { SelectOption } from '@/types/values';

import OptionItem from '../OptionItem';
import { SelectSize } from '../types';

import s from './OptionList.module.scss';

type Props<V extends string | number> = {
  className?: string;
  onClick: (id: V) => void;
  open: boolean;
  options: SelectOption<V>[];
  size: SelectSize;
  values: Set<V>;
};

const OptionList = <V extends string | number>({
  className,
  onClick,
  open,
  options,
  size,
  values,
}: Props<V>): React.JSX.Element => {
  return (
    <div className={cn(s.list, open && s[`list_open-${size}`], className)}>
      {options.map((item) => (
        <OptionItem key={item.id} item={item} checked={values.has(item.id)} onClick={onClick} size={size} />
      ))}
    </div>
  );
};

export default OptionList;
