import { observer } from 'mobx-react-lite';
import * as React from 'react';

import InputField from '@/components/form/InputField';
import PhoneField from '@/components/form/PhoneField';
import TextareaField from '@/components/form/TextareaField';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useCreateLeadFormStore } from '@/stores/local/CreateLeadFormStore';

import s from '../CreateLeadModal.module.scss';

type Props = {
  className?: string;
};

const ClientForm: React.FC<Props> = ({ className }) => {
  const { clientComment, clientEmail, clientFirstName, clientLastName, clientPhone } = useCreateLeadFormStore();

  return (
    <div className={className}>
      <Typo view={TypoView.h3} element={TypoElement.h3}>
        Клиент
      </Typo>
      <PhoneField {...clientPhone.props} />
      <div className={s.form__doubleField}>
        <InputField {...clientFirstName.props} />
        <InputField {...clientLastName.props} />
      </div>
      <InputField {...clientEmail.props} />
      <TextareaField {...clientComment.props} rows={5} />
    </div>
  );
};

export default observer(ClientForm);
