import * as React from 'react';

export const useWidthSetting = (handleResize: () => void) => {
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};
