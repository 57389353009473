import { action, makeObservable, observable } from 'mobx';

import { IUser, UserServer } from '@/entities/user';
import { capitalizeFirstLetter } from '@/utils/capitalizeFistLetter';

export class UserModel implements IUser {
  readonly id;
  readonly agencyId;
  readonly phone;
  readonly email;
  readonly externalId;
  readonly lastName;
  readonly firstName;
  readonly role;
  readonly isBlocked: boolean;
  private _unreadNotificationsCount: number;

  constructor({
    id,
    agencyId,
    phone,
    email,
    externalId,
    firstName,
    lastName,
    role,
    isBlocked,
    unreadNotificationsCount,
  }: IUser) {
    this.id = id;
    this.agencyId = agencyId;
    this.phone = phone;
    this.email = email;
    this.externalId = externalId;
    this.lastName = lastName;
    this.firstName = firstName;
    this.role = role;
    this.isBlocked = isBlocked;
    this._unreadNotificationsCount = unreadNotificationsCount;

    makeObservable<this, '_unreadNotificationsCount'>(this, {
      _unreadNotificationsCount: observable,
      setUnreadNotificationsCount: action,
    });
  }

  get fullName(): string {
    return `${capitalizeFirstLetter(this.lastName)} ${capitalizeFirstLetter(this.firstName)[0]}.`;
  }

  get unreadNotificationsCount(): number {
    return this._unreadNotificationsCount;
  }

  setUnreadNotificationsCount(value: number): void {
    this._unreadNotificationsCount = value;
  }

  static fromJson(raw: UserServer): UserModel {
    return new UserModel({
      id: raw.id,
      agencyId: raw.agency_id,
      phone: raw.phone,
      email: raw.email,
      externalId: raw.external_id,
      lastName: raw.last_name,
      firstName: raw.first_name,
      role: raw.role,
      isBlocked: raw.is_blocked ?? false,
      unreadNotificationsCount: raw.unread_notifications_count,
    });
  }
}
