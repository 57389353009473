import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';

import { ValidatorResult } from '@/types/validator';

import s from './FieldError.module.scss';

type FieldErrorProps = {
  className?: string;
  error?: ValidatorResult;
};

const FieldError: React.FC<FieldErrorProps> = ({ className, error }) => {
  return (
    <AnimatePresence>
      {error && (
        <motion.div
          className={cn(s.error, className)}
          style={{ overflowY: 'hidden' }}
          initial={{
            height: 0,
            opacity: 0,
            marginTop: 0,
            marginLeft: 0,
          }}
          animate={{
            height: 'auto',
            opacity: 1,
            marginTop: 8,
          }}
          exit={{ height: 0, opacity: 0, marginTop: 0, marginLeft: 0 }}
        >
          {error}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(FieldError);
