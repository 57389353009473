export enum CreateLeadFormFields {
  managerId = 'managerId',

  isSubAgent = 'isSubAgent',
  subAgentFullName = 'subAgentFullName',
  subAgentPhone = 'subAgentPhone',

  project = 'project',
  placeType = 'placeType',

  clientFirstName = 'clientFirstName',
  clientLastName = 'clientLastName',
  clientPhone = 'clientPhone',
  clientEmail = 'clientEmail',
  clientComment = 'clientComment',
  source = 'source',
}

export const WITH_SUBAGENT_FIELDS: CreateLeadFormFields[] = [
  CreateLeadFormFields.subAgentFullName,
  CreateLeadFormFields.subAgentPhone,
  CreateLeadFormFields.clientFirstName,
  CreateLeadFormFields.clientPhone,
  CreateLeadFormFields.clientEmail,
  CreateLeadFormFields.clientComment,
  CreateLeadFormFields.project,
  CreateLeadFormFields.placeType,
];

export const WITHOUT_SUBAGENT_FIELDS: CreateLeadFormFields[] = [
  CreateLeadFormFields.managerId,
  CreateLeadFormFields.clientFirstName,
  CreateLeadFormFields.clientPhone,
  CreateLeadFormFields.clientEmail,
  CreateLeadFormFields.clientComment,
  CreateLeadFormFields.project,
  CreateLeadFormFields.placeType,
];
