import * as React from 'react';

import Select from '@/components/form/Select/Select';
import { TypoElement, TypoView } from '@/components/Typo';
import Typo from '@/components/Typo/Typo';
import { DictionaryFields } from '@/stores/global/DictionariesStore';
import { useDictionariesStore } from '@/stores/global/RootStore';
import { withRequiredSymbol } from '@/utils/withRequiredSymbol';

import s from '../CreateLeadModal.module.scss';

type Props = {
  className?: string;
  value: string | null;
  onChange: (value: string | null) => void;
};

const SourceSelect: React.FC<Props> = ({ className, onChange, value }) => {
  const dictionaries = useDictionariesStore();

  if (!dictionaries[DictionaryFields.sources].length) {
    return null;
  }

  return (
    <div className={className}>
      <Typo className={s.grid_caption} view={TypoView.h3} element={TypoElement.h3}>
        {withRequiredSymbol('Тип источника', false)}
      </Typo>
      <Select
        variant="vertical"
        className={s.form__block_narrow}
        multiple={false}
        placeholder="Выберите тип источника"
        options={dictionaries[DictionaryFields.sources].items.map((item) => ({
          id: item.slug,
          label: item.title,
        }))}
        size="large"
        value={value}
        onChange={onChange}
        popupPosition={['bottom left']}
      />
    </div>
  );
};

export default SourceSelect;
