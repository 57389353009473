export enum CheckboxType {
  checkbox = 'checkbox',
  radio = 'radio',
}

export enum CheckboxSize {
  s = 's',
  m = 'm',
  l = 'l',
}

export type CheckboxProps<V extends string | number = string> = {
  value?: V;
  onChange?: (checked: boolean, value: V) => void;
  caption?: string;
  type?: CheckboxType;
  size?: CheckboxSize;
  captionBorderColor?: string;
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'value' | 'type' | 'size'>;
