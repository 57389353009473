import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

import Alert from './Alert/Alert';

import s from './Alerts.module.scss';

type Props = {
  className?: string;
};

const Alerts = ({ className }: Props): React.ReactElement<Props> => {
  const { alertsStore } = useRootStore();
  const { alerts, removeAlert } = alertsStore;

  return (
    <ul className={cn(s.alerts, className)}>
      <AnimatePresence>
        {alerts.items.map((n) => (
          <motion.li
            key={n.id}
            initial={{
              top: '-110px',
            }}
            animate={{
              top: '24px',
            }}
            className={s.alerts__container}
            exit={{ top: '-110px' }}
          >
            <Alert alert={n} onRemove={() => removeAlert(n.id)} />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
};

export default observer(Alerts);
