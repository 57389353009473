import cn from 'classnames';
import * as React from 'react';

import { ButtonSize, ButtonType } from '@/components/Button';
import Button from '@/components/Button/Button';
import Icon from '@/components/Icon';

import { SelectHorizontalView } from '../SelectHorizontal/config';

import s from './OptionButton.module.scss';

type Props<V extends string | number> = React.PropsWithChildren<
  Partial<{
    className: string;
    disabled: boolean;
    checked: boolean | null;
    willAllSelected: boolean;
    id: V;
    onClick: (id: V | null) => void;
    view: SelectHorizontalView;
  }>
>;

const BUTTON_VIEWS: Record<SelectHorizontalView, ButtonSize> = {
  [SelectHorizontalView.default]: ButtonSize.small,
  [SelectHorizontalView.small]: ButtonSize.custom,
};

const OptionButton = <V extends string | number>({
  children,
  className,
  checked = null,
  willAllSelected = false,
  id,
  onClick,
  view = SelectHorizontalView.default,
  ...props
}: Props<V>) => {
  const buttonView = checked === null ? ButtonType.secondaryOutline : checked ? ButtonType.primary : ButtonType.outline;

  const handleClick = React.useCallback(() => {
    if (willAllSelected && !checked) {
      onClick?.(null);
    } else {
      onClick?.(id ?? null);
    }
  }, [id, onClick, checked, willAllSelected]);

  return (
    <Button
      {...props}
      onClick={handleClick}
      className={cn(s.option, className, s[`option_view_${view}`])}
      view={buttonView}
      size={BUTTON_VIEWS[view]}
      element="button"
      after={buttonView === ButtonType.secondaryOutline && <Icon iconName="IconClose" size={20} />}
    >
      {children}
    </Button>
  );
};

export default React.memo(OptionButton) as typeof OptionButton;
