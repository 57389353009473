import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonSize } from '@/components/Button';
import Icon from '@/components/Icon';
import Typo, { TypoAlign, TypoView } from '@/components/Typo';

import s from './DialogStub.module.scss';

export type DialogContentProps = {
  text: string;
  buttonText?: string;
  onClick?: VoidFunction;
};

type Props = DialogContentProps & {
  withPlus?: boolean;
  className?: string;
};

const DialogStub: React.FC<Props> = ({ text, buttonText, onClick, withPlus, className }) => {
  return (
    <div className={cn(s.content, className)}>
      <Typo className={s.content__text} view={TypoView.h4} textAlign={TypoAlign.center}>
        {text}
      </Typo>
      {buttonText && (
        <Button
          className={s.content__button}
          before={withPlus && <Icon size={20} iconName="IconPlus" color="white" />}
          size={ButtonSize.small}
          element="button"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default React.memo(DialogStub);
