import { computed, makeObservable } from 'mobx';

import { BreakpointEnum, BREAKPOINTS } from '@/configs/width';
import { ValueModel } from '@/models/ValueModel';

import { IUIStore } from './types';

export class UIStore implements IUIStore {
  private widthModel: ValueModel<number> = new ValueModel(window.innerWidth);

  constructor() {
    makeObservable(this, {
      width: computed,
      isPhone: computed,
      isTablet: computed,
      isLessThanDesktop: computed,
    });
  }

  get width(): number {
    return this.widthModel.value;
  }

  get isPhone(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.sm];
  }

  get isTablet(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.xm] && this.width >= BREAKPOINTS[BreakpointEnum.sm];
  }

  get isLessThanDesktop(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.xm];
  }

  setWidth = () => {
    this.widthModel.change(window.innerWidth);
  };
}
