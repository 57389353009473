import * as React from 'react';

import logo from '@/assets/forma-logo.svg?url';
import BaseClickable from '@/components/BaseClickable';
import Picture from '@/components/Picture';
import { routerUrls } from '@/configs/routes';

import s from './Logo.module.scss';

type LogoProps = {
  className?: string;
};

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <BaseClickable className={className} element="link" to={routerUrls.main.create()}>
      <Picture src={logo} alt="Логотип FORMA" width={144} height={31} className={s.logo} />
    </BaseClickable>
  );
};

export default React.memo(Logo);
