import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon from '@/components/Icon';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { NotificationType } from '@/entities/notification';
import { NotificationModelType } from '@/models/NotificationModel';
import { LeadModalStoreOpenType } from '@/stores/global/ModalsStore/LeadModalStore';
import { useRootStore } from '@/stores/global/RootStore';
import { formatDateDDMMMMYYYYHMM } from '@/utils/formatDate';

import { ICON_MAP } from './config';

import s from './NotificationCard.module.scss';

type Props = {
  item: NotificationModelType;
};

const NotificationCard: React.FC<Props> = ({ item }: Props) => {
  const {
    modalsStore: { notificationListModal, leadModalStore },
  } = useRootStore();

  const handleClick = React.useCallback(() => {
    if (item.type !== NotificationType.fixationPeriod) {
      return;
    }

    notificationListModal.close();
    leadModalStore.open({
      type: LeadModalStoreOpenType.id,
      value: item.extra.leadId,
      isNotificationBack: true,
    });
  }, []);

  const isFixationPeriod = item.type === NotificationType.fixationPeriod;

  return (
    <div className={cn(s.card, isFixationPeriod && s.card__clickable)} onClick={handleClick}>
      <div className={s['card__icon-wrapper']}>
        <Icon iconName={ICON_MAP[item.type]} size={24} />
        {!item.read && <div className={s['card__icon-status']} />}
      </div>
      <div className={s['card__content']}>
        <Typo block view={TypoView.textSmall} className={s.card__date} color="grey-main">
          {formatDateDDMMMMYYYYHMM(item.createdDate)}
        </Typo>
        <Typo block weight={TypoWeight.medium}>
          {item.title}
        </Typo>
        <Typo block view={TypoView.textSmall}>
          {item.text}
        </Typo>
        {isFixationPeriod && (
          <div className={s['card__link']}>
            <Typo block view={TypoView.textSmall} underline>
              К заявке
            </Typo>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(NotificationCard);
