import { NotificationType } from '@/entities/notification';
import { SelectOption } from '@/types/values';

export const NOTIFICATION_TYPE_OPTIONS: SelectOption<NotificationType>[] = [
  {
    id: NotificationType.fixationPeriod,
    label: 'Истечение срока фиксации',
  },
  {
    id: NotificationType.other,
    label: 'Другое',
  },
];
