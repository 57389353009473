import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView } from '@/components/Typo';
import { callCenterFormattedNumber, callCenterNumber } from '@/configs/app';

import s from './MeetingNoticeModal.module.scss';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
};

const MeetingNoticeModal: React.FC<Props> = ({ opened, onClose }) => {
  return (
    <Modal
      variant={ModalVariant.Confirm}
      opened={opened}
      onClose={onClose}
      primaryButtonOnClick={onClose}
      primaryButtonText="Хорошо"
      ariaLabel={`Для записи на встречу позвоните по номеру: ${callCenterFormattedNumber}`}
      title={
        <Typo view={TypoView.h5}>
          Для записи на встречу,
          <br />
          позвоните по номеру:{' '}
          <a href={`tel:${callCenterNumber}`} className={s.notice__phone}>
            {callCenterFormattedNumber}
          </a>
        </Typo>
      }
    />
  );
};

export default MeetingNoticeModal;
