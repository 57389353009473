import { formatPhoneNumber } from 'react-phone-number-input';
export const MANAGER_FORMA_DOMAIN =
  process.env.NODE_ENV === 'development' ? process.env.MANAGER_FORMA_DOMAIN : 'manager.forma.ru';

export const getRedirectUrl = () => `${document.location.origin}/`;

export const LINKS = {
  // "Войти"
  auth: `https://${MANAGER_FORMA_DOMAIN}/?redirect_url=${getRedirectUrl()}`,
  // "Провести встречу с клиентом"
  clientAppointment: `https://${MANAGER_FORMA_DOMAIN}/lk`,
  // "Записаться на брокер-тур"
  brokerTour: `https://forma-developer.timepad.ru`,
  // "Посчитать субсидированную ипотеку"
  calculator: `https://${MANAGER_FORMA_DOMAIN}/calculator`,
};

export const callCenterNumber = '+74951049488';
export const callCenterFormattedNumber = formatPhoneNumber(callCenterNumber);

export const supportEmail = 'partner@forma.ru';
