import { Nullable, SelectOption } from '@/types/values';

import { LeadStatus } from './server';

export enum LeadFields {
  id = 'id',
  comment = 'comment',
  createdDate = 'createdDate',
  expiryDate = 'expiryDate',
  managerId = 'managerId',
  managerFullName = 'managerFullName',
  fullName = 'fullName',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
  placeType = 'placeType',
  project = 'project',
  status = 'status',
  subAgent = 'subAgent',
  crmRejectionInfo = 'crmRejectionInfo',
}

type SubAgent = {
  name: string;
  phone: string | null;
};

export type CrmRejectionInfo = Partial<{
  leadId: string;
  manager: {
    name: Nullable<string>;
    phone: Nullable<string>;
  };
  agency: {
    title: Nullable<string>;
  };
  message: Nullable<string>;
  holdDate: Nullable<string>;
}>;

export type ShortLeadData = {
  fullName: string;
  phone: string;
  createdDate: string;
  holdDate: Nullable<string>;
};

export interface ILead {
  [LeadFields.id]: number;
  [LeadFields.comment]: string;
  [LeadFields.createdDate]: string;
  [LeadFields.expiryDate]?: string;
  [LeadFields.managerId]: number;
  [LeadFields.managerFullName]: string;
  [LeadFields.fullName]: string;
  [LeadFields.firstName]: string;
  [LeadFields.lastName]?: string;
  [LeadFields.phone]: string;
  [LeadFields.email]: string;
  [LeadFields.placeType]: string;
  [LeadFields.project]: string;
  [LeadFields.status]: LeadStatus;
  [LeadFields.subAgent]: Nullable<SubAgent>;
  [LeadFields.crmRejectionInfo]: Nullable<CrmRejectionInfo>;
}

export const leadStatusEntities: Record<LeadStatus, SelectOption<LeadStatus>> = {
  [LeadStatus.unique]: {
    id: LeadStatus.unique,
    label: 'Уникален',
  },
  [LeadStatus.wasUnique]: {
    id: LeadStatus.wasUnique,
    label: 'Был уникален',
  },
  [LeadStatus.nonUnique]: {
    id: LeadStatus.nonUnique,
    label: 'Неуникален',
  },
};
export const leadStatusKeys: LeadStatus[] = [LeadStatus.unique, LeadStatus.wasUnique, LeadStatus.nonUnique];
export const leadStatusOptions: SelectOption<LeadStatus>[] = leadStatusKeys.map((key) => leadStatusEntities[key]);
