import * as React from 'react';

import Button, { ButtonSize } from '@/components/Button';
import Typo, { TypoAlign, TypoView } from '@/components/Typo';

import s from './ErrorBoundary.module.scss';

type ErrorBoundaryProps = {
  className?: string;
};

const ErrorBoundary = (): React.ReactElement<ErrorBoundaryProps> => {
  return (
    <div className={s['error-boundary']}>
      <div className={s['error-boundary__inner-container']}>
        <Typo className={s['error-boundary__text']} view={TypoView.h4} textAlign={TypoAlign.center}>
          Произошла неизвестная ошибка
        </Typo>
        <Button className={s['error-boundary__button']} size={ButtonSize.small} element="a" href="/">
          Перейти на главную
        </Button>
      </div>
    </div>
  );
};

export default ErrorBoundary;
