import cn from 'classnames';
import * as React from 'react';

import Icon from '@/components/Icon';
import Typo, { TypoElement } from '@/components/Typo';
import { SelectOption } from '@/types/values';

import { SelectSize } from '../types';

import s from './OptionItem.module.scss';

type Props<V extends string | number> = {
  item: SelectOption<V>;
  checked: boolean;
  onClick: (id: V) => void;
  size: SelectSize;
};

const OptionItem = <V extends string | number>({ item, checked, onClick, size }: Props<V>) => {
  const handleClick = React.useCallback(() => {
    onClick(item.id);
  }, [item.id, onClick]);

  return (
    <div className={cn(s.item, s[`item_${size}`], checked && s.item_checked)} onClick={handleClick}>
      <Typo element={TypoElement.p} color={checked ? 'white' : 'grey-main'}>
        {item.label}
      </Typo>
      {checked && <Icon iconName="IconCheck" size={20} color="white" />}
    </div>
  );
};

export default React.memo(OptionItem) as typeof OptionItem;
