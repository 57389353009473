import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import BaseField, { BaseFieldProps } from '../BaseField';

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>;

export type InputFieldProps = BaseFieldProps &
  InputProps & {
    ariaLabel?: string;
    onChange?: (value: string) => void;
  };

const InputField = (
  {
    className,
    after,
    caption,
    subcaption,
    disabled,
    error,
    filled,
    focused,
    isError,
    onBlur,
    onChange,
    onFocus,
    ariaLabel,
    ...props
  }: InputFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  const { opened: active, open: focus, close: blur } = useToggleState();

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>): void => {
      focus();
      onFocus?.(e);
    },
    [focus, onFocus],
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      blur();
      onBlur?.(e);
    },
    [blur, onBlur],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  return (
    <BaseField
      className={className}
      caption={caption}
      subcaption={subcaption}
      disabled={disabled}
      active={active}
      error={error}
      isError={isError}
      filled={filled}
      focused={focused}
      after={after}
      required={props.required}
      ariaLabel={ariaLabel}
    >
      <input
        ref={ref}
        className="clean-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
    </BaseField>
  );
};

export default React.forwardRef(InputField) as typeof InputField;
