import { SelectOption } from '@/types/values';

import { UserRole } from './server';

type AdminUserOptions = UserRole;
type RegularUserOptions = UserRole.fixator | UserRole.manager;

export const regularUserOptions: SelectOption<RegularUserOptions>[] = [
  {
    id: UserRole.fixator,
    label: 'Фиксатор',
  },
  {
    id: UserRole.manager,
    label: 'Менеджер',
  },
];

export const adminUserRoleOptions: SelectOption<AdminUserOptions>[] = [
  {
    id: UserRole.director,
    label: 'Руководитель',
  },
  ...regularUserOptions,
];
