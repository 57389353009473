import { ISource, SourceServer } from '@/entities/dictionaries';

export class SourceModel implements ISource {
  readonly id;
  readonly title;
  readonly slug;

  constructor({ id, title, slug }: ISource) {
    this.id = id;
    this.title = title;
    this.slug = slug;
  }

  static fromJson({ id, title, slug }: SourceServer): SourceModel {
    return new SourceModel({
      id,
      title,
      slug,
    });
  }
}
