export const formaEmployees: FormaEmployee[] = [
  {
    name: 'Гагуа Давид',
    email: 'gaguadv@forma.ru',
    position: 'Менеджер по работе с агентствами недвижимости',
    telegram: 'D_Gagua',
  },
  {
    name: 'Кабанова Мария',
    email: 'kabanovams@forma.ru',
    position: 'Менеджер по работе с агентствами недвижимости',
    telegram: 'KabanovaMariaF',
  },
];

export type FormaEmployee = {
  name: string;
  email: string;
  telegram: string;
  position: string;
};
