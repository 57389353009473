import cn from 'classnames';
import * as React from 'react';

import Icon from '@/components/Icon';
import Typo, { TypoElement } from '@/components/Typo';

import { CheckboxProps, CheckboxSize, CheckboxType } from './types';

import s from './Checkbox.module.scss';

const ICON_SIZE: Record<CheckboxSize, number> = {
  [CheckboxSize.l]: 16,
  [CheckboxSize.m]: 14,
  [CheckboxSize.s]: 10.5,
};

const Checkbox = <V extends string | number = string>({
  className,
  onChange,
  caption,
  type = CheckboxType.checkbox,
  size = CheckboxSize.m,
  captionBorderColor,
  ...props
}: CheckboxProps<V>): React.ReactElement => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    ({ target: { checked } }) => {
      onChange?.(checked, props.value as V);
    },
    [onChange],
  );

  return (
    <label className={cn(s.checkbox, props.disabled && s.checkbox_disabled, className)}>
      <input className="visually-hidden" type={type} onChange={handleChange} {...props} />
      <div
        className={cn(s.checkbox__custom, s[`checkbox__custom_size-${size}`], s[`checkbox__custom_view-${type}`], {
          [s.checkbox__custom_checked]: props.checked,
        })}
      >
        {type === CheckboxType.checkbox && (
          <Icon className={s.checkbox__icon} iconName="IconCheck" size={ICON_SIZE[size]} />
        )}
      </div>
      {caption && (
        <Typo
          className={cn(s.checkbox__caption, captionBorderColor && s['checkbox__caption-border'])}
          style={{ borderColor: captionBorderColor }}
          element={TypoElement.span}
        >
          {caption}
        </Typo>
      )}
    </label>
  );
};

export default Checkbox;
