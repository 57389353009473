import { capitalizeFirstLetter } from './capitalizeFistLetter';

type Params = {
  firstName: string;
  lastName?: string;
  reversed?: boolean;
};

/**
 * Возвращает имя сотрудника
 * - в формате «Фамилия Имя» при параметре reversed: false (по умолчанию)
 * - в формате «Имя Фамилия» при параметре reversed: true
 */
export const getFullName = ({ firstName, lastName, reversed }: Params) => {
  const firstNameCapitalized = capitalizeFirstLetter(firstName);
  const lastNameCapitalized = lastName ? capitalizeFirstLetter(lastName) : '';

  if (!lastName) {
    return firstNameCapitalized;
  }

  return reversed ? `${firstNameCapitalized} ${lastNameCapitalized}` : `${lastNameCapitalized} ${firstNameCapitalized}`;
};
