import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLocalStore } from '@kts-front/hooks';

import InfoGrid from '@/components/InfoGrid';
import SourceSelect from '@/components/leads/CreateLeadModal/SourceSelect';
import Modal, { ModalVariant } from '@/components/Modal';
import { ILead } from '@/entities/lead';
import { useRootStore } from '@/stores/global/RootStore';
import { CreateLeadFormStore, CreateLeadFormStoreContext } from '@/stores/local/CreateLeadFormStore';
import { ApiErrorCode } from '@/types/meta';

import CreateLeadResultModal from '../CreateLeadResultModal';

import ClientForm from './ClientForm';
import ErrorModal from './ErrorModal';
import ManagerForm from './ManagerForm';
import ProjectAndPlaceType from './ProjectAndPlaceType';
import { useFormattedInfo } from './useFormattedInfo';

import s from './CreateLeadModal.module.scss';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
  client?: ILead;
  refetch?: VoidFunction;
};

const CreateLeadModal: React.FC<Props> = observer(({ client, opened, onOpen, onClose, refetch }) => {
  const rootStore = useRootStore();

  const { store } = useLocalStore(() => new CreateLeadFormStore({ rootStore, client }));
  const {
    isServerError,
    isFieldError,
    submitStage,
    resetServerError,
    reset,
    submit,
    resultModalState,
    closeResultModal,
    errorModalState,
    source,
  } = store;

  React.useEffect(() => {
    if (!opened) {
      reset();
    }
  }, [opened]);

  const { clientInfo, subAgentInfo } = useFormattedInfo(client);

  const handleSubmitCreateModal = React.useCallback(async () => {
    const result = await submit();

    if (!result.isError) {
      onClose();
      refetch?.();

      return;
    }

    if (result.data.reason === ApiErrorCode.nonuniqueBid) {
      onClose();
      refetch?.();
    }
  }, [refetch, onClose, submit]);

  const handleSubmitResultModal = React.useCallback(() => {
    closeResultModal();
    onOpen();
  }, [closeResultModal, onOpen]);

  const { isPhone } = useRootStore().uiStore;

  return (
    <CreateLeadFormStoreContext.Provider value={store}>
      <ErrorModal opened={errorModalState.isOpen} onClose={errorModalState.close} />
      <CreateLeadResultModal
        state={resultModalState.value}
        onClose={closeResultModal}
        onSubmit={handleSubmitResultModal}
      />
      <Modal
        className={s.form}
        variant={ModalVariant.RightWindow}
        header="Заявка на фиксацию"
        ariaLabel="Заявка на фиксацию"
        error={isServerError ? <>Внутренняя ошибка сервера.{isPhone && <br />}Попробуйте позже.</> : null}
        onCloseError={resetServerError}
        primaryButtonText="Отправить заявку"
        primaryButtonOnClick={handleSubmitCreateModal}
        primaryButtonVisuallyDisabled={isFieldError || isServerError}
        primaryButtonLoading={submitStage.isLoading}
        onClose={onClose}
        opened={opened}
      >
        <ManagerForm />
        {clientInfo && (
          <div className={cn(s.form__block, s.form__block_largeRowGap)}>
            <InfoGrid title="Клиент" info={clientInfo} />
            {subAgentInfo && <InfoGrid title="Субагент" info={subAgentInfo} />}
          </div>
        )}
        <ProjectAndPlaceType className={s.form__block} />
        <SourceSelect className={s.form__block} value={source.value} onChange={source.onChange} />
        {!clientInfo && <ClientForm className={s.form__block} />}
      </Modal>
    </CreateLeadFormStoreContext.Provider>
  );
});

export default CreateLeadModal;
