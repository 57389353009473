import * as React from 'react';

import OptionButton from '../OptionButton';

import s from './SelectedList.module.scss';

type Props<V extends string | number> = {
  values: Set<V>;
  onChange: (id: V | null) => void;
  optionsMap: Record<V, string>;
};

const SelectedList = <V extends string | number>({ values, onChange, optionsMap }: Props<V>) => {
  return (
    <div className={s.list}>
      {Array.from(values.values()).map((id) => (
        <OptionButton key={id} id={id} onClick={onChange}>
          {optionsMap[id]}
        </OptionButton>
      ))}
    </div>
  );
};

export default React.memo(SelectedList) as typeof SelectedList;
