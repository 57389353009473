import { useDrag } from '@use-gesture/react';
import { useRef, useState } from 'react';

const MAX_SWIPE = 80;
const DISAPPEARANCE_TIME = 300;

export const useDragModalBottom = (onClose: VoidFunction, isScrollable: boolean) => {
  const [swipeY, setSwipeY] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const dragModalBottom = useDrag(
    ({ movement: [, my], last, event }) => {
      if (isScrollable) {
        const target = event.target as HTMLElement;

        if (!headerRef.current?.contains(target)) {
          return;
        }
      }

      if (!last) {
        setIsDragging(true);
        setSwipeY(my > 0 ? my : 0);

        return;
      }

      setIsDragging(false);

      // Порог свайпа для закрытия
      if (my > MAX_SWIPE) {
        setSwipeY(window.innerHeight);
        setTimeout(onClose, DISAPPEARANCE_TIME);
      }
      // Если порог не достигнут - возвращаем в исходное положение
      else {
        setSwipeY(0);
      }
    },
    { axis: 'y' },
  );

  return { dragModalBottom, swipeY, isDragging, headerRef };
};
