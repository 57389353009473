import { action, computed, makeObservable } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { CreateLeadPayload, CrmRejectionInfoServer, ILead } from '@/entities/lead';
import { UserRole } from '@/entities/user';
import { CheckboxGridModel } from '@/models/CheckboxGridModel';
import { CheckboxModel } from '@/models/CheckboxModel';
import { InputModel } from '@/models/InputModel';
import { LeadModel } from '@/models/LeadModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { IDictionariesStore } from '@/stores/global/DictionariesStore';
import { IRootStore } from '@/stores/global/RootStore';
import { ApiErrorCode, ApiErrorData, ApiErrorDataWithFields } from '@/types/meta';
import { Nullable } from '@/types/values';
import { validateMaxLength, validateNotEmpty, validateNotEmptyEmail, validatePhoneNumber } from '@/utils/validators';

import { LocalStore } from '../LocalStore';

import { CreateLeadFormFields, WITH_SUBAGENT_FIELDS, WITHOUT_SUBAGENT_FIELDS } from './config';
import { ResultModalState, ResultType } from './types';

type Params = {
  rootStore: IRootStore;
  client?: ILead;
};

export class CreateLeadFormStore extends LocalStore {
  readonly [CreateLeadFormFields.managerId] = new InputModel<number | null>({
    name: 'manager_id',
    caption: 'Выберите менеджера',
    required: true,
    initialValue: null,
  });

  readonly [CreateLeadFormFields.isSubAgent] = new CheckboxModel({
    name: 'is_sub_agent',
    caption: 'Клиент привлечён с помощью субагента',
    initialValue: 'isSubAgent',
    checked: false,
  });

  readonly [CreateLeadFormFields.subAgentFullName] = new InputModel({
    name: 'sub_agent_full_name',
    caption: 'Фамилия, имя субагента',
    initialValue: '',
    validators: [validateNotEmpty()],
  });

  readonly [CreateLeadFormFields.subAgentPhone] = new InputModel({
    name: 'sub_agent_phone',
    caption: 'Телефон',
    initialValue: '',
    validators: [validateNotEmpty(), validatePhoneNumber()],
  });

  readonly [CreateLeadFormFields.clientFirstName] = new InputModel({
    name: 'client_first_name',
    caption: 'Имя',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty()],
  });

  readonly [CreateLeadFormFields.clientLastName] = new InputModel({
    name: 'client_last_name',
    caption: 'Фамилия',
    initialValue: '',
  });

  readonly [CreateLeadFormFields.clientPhone] = new InputModel({
    name: 'client_phone',
    caption: 'Телефон',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty(), validatePhoneNumber()],
  });

  readonly [CreateLeadFormFields.clientEmail] = new InputModel({
    name: 'client_email',
    caption: 'Email',
    required: false,
    initialValue: '',
    validators: [validateNotEmptyEmail()],
  });

  readonly [CreateLeadFormFields.clientComment] = new InputModel<string, HTMLTextAreaElement>({
    name: 'client_comment',
    caption: 'Комментарий',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty(), validateMaxLength(100, 'Максимальное количество символов - 100')],
  });

  readonly [CreateLeadFormFields.project] = new CheckboxGridModel<number | null>({
    name: 'project',
    caption: 'Проект',
    required: true,
    initialValue: null,
    validators: [validateNotEmpty('Необходимо выбрать проект')],
  });

  readonly [CreateLeadFormFields.placeType] = new CheckboxGridModel<string | null>({
    name: 'place_type',
    caption: 'Вид помещения',
    required: true,
    initialValue: null,
    validators: [validateNotEmpty('Необходимо выбрать вид помещения')],
  });

  readonly [CreateLeadFormFields.source] = new CheckboxGridModel<string | null>({
    name: 'source',
    caption: 'Тип источника',
    initialValue: null,
  });

  readonly submitStage = new LoadingStageModel();
  readonly resultModalState = new ValueModel<Nullable<ResultModalState>>(null);
  readonly errorModalState = new ToggleModel(false);

  private _rootStore: IRootStore;
  private _client: Nullable<ILead> = null;
  private _isServerErrorModel = new ValueModel(false);

  private _submitRequest = apiStore.createRequest<CreateLeadPayload>({
    method: 'POST',
    url: apiUrls.leads.create,
  });

  private _getManagerId(): number | null {
    if (this.isManagerRole) {
      return this._rootStore.userStore.user?.id ?? null;
    }

    if (this[CreateLeadFormFields.isSubAgent].checked) {
      return this._rootStore.userStore.user?.id ?? null;
    }

    return this[CreateLeadFormFields.managerId].value || this._rootStore.userStore.user?.id || null;
  }

  private _toServerFormat(): Nullable<CreateLeadPayload> {
    const managerId = this._getManagerId();
    const projectId = this[CreateLeadFormFields.project].value;
    const place_type = this[CreateLeadFormFields.placeType].value;

    if (!managerId || !projectId || !place_type) {
      return null;
    }

    const sub_agent = this[CreateLeadFormFields.isSubAgent].checked
      ? {
          name: this[CreateLeadFormFields.subAgentFullName].value,
          phone: this[CreateLeadFormFields.subAgentPhone].value,
        }
      : null;

    return {
      client: {
        first_name: this[CreateLeadFormFields.clientFirstName].value,
        last_name: this[CreateLeadFormFields.clientLastName].value ?? undefined,
        phone: this[CreateLeadFormFields.clientPhone].value,
        email: this[CreateLeadFormFields.clientEmail].value,
        comment: this[CreateLeadFormFields.clientComment].value,
      },
      manager: {
        id: managerId,
      },
      project: {
        id: projectId,
      },
      place_type,
      sub_agent,
      source_type: this[CreateLeadFormFields.source].value,
    };
  }

  private _setDefaultValues(): void {
    const firstProject = this.dictionaries.projects.items[0];

    if (firstProject) {
      this[CreateLeadFormFields.project].change(firstProject.id);
      this[CreateLeadFormFields.placeType].change(firstProject.placeTypes.values().next().value);
    }

    const client = this._client;

    if (client) {
      this[CreateLeadFormFields.clientFirstName].change(client.firstName);
      this[CreateLeadFormFields.clientLastName].change(client.lastName ?? '');
      this[CreateLeadFormFields.clientPhone].change(client.phone);
      this[CreateLeadFormFields.clientEmail].change(client.email);
      this[CreateLeadFormFields.clientComment].change(client.comment);
      this[CreateLeadFormFields.managerId].change(client.managerId);
    }

    if (!this.isManagerRole && client?.subAgent) {
      this[CreateLeadFormFields.isSubAgent].onChange(true, 'isSubAgent');
      this[CreateLeadFormFields.subAgentFullName].change(client.subAgent.name);
      this[CreateLeadFormFields.subAgentPhone].change(client.subAgent.phone || '');
    }

    if (this._rootStore.userStore.user && this.isManagerRole) {
      this[CreateLeadFormFields.managerId].change(this._rootStore.userStore.user.id);
    }
  }

  constructor({ rootStore, client }: Params) {
    super();

    this._rootStore = rootStore;
    this._client = client ?? null;

    this._setDefaultValues();

    makeObservable(this, {
      expectedPlaceTypes: computed,
      isFieldError: computed,
      isServerError: computed,
      isManagerRole: computed,
      hasSelectedSubAgent: computed,

      changeIsSubAgent: action.bound,
      changeProject: action.bound,
      closeResultModal: action.bound,
      resetServerError: action.bound,
      reset: action.bound,
      scrollToErrorField: action.bound,
    });
  }

  private get fieldsToValidate(): CreateLeadFormFields[] {
    return this[CreateLeadFormFields.isSubAgent].checked ? WITH_SUBAGENT_FIELDS : WITHOUT_SUBAGENT_FIELDS;
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get hasSelectedSubAgent(): boolean {
    return Boolean(this._client?.subAgent);
  }

  get isManagerRole(): boolean {
    return this._rootStore.userStore.user?.role === UserRole.manager;
  }

  get isServerError(): boolean {
    return this._isServerErrorModel.value;
  }

  get isFieldError(): boolean {
    let isError = false;

    this.fieldsToValidate.forEach((field) => {
      if (this[field].isError) {
        isError = true;
      }
    });

    return isError;
  }

  get expectedPlaceTypes(): Set<string> | undefined {
    const projectId = this[CreateLeadFormFields.project].value;

    if (!projectId) {
      return;
    }

    return this.dictionaries.projects.getEntity(projectId)?.placeTypes;
  }

  closeResultModal() {
    this.resultModalState.change(null);
  }

  scrollToErrorField = () => {
    const errorField = this.fieldsToValidate.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  changeIsSubAgent(checked: boolean): void {
    this[CreateLeadFormFields.isSubAgent].onChange(checked, 'isSubAgent');

    this[CreateLeadFormFields.subAgentFullName].changeRequired(checked);
    this[CreateLeadFormFields.subAgentPhone].changeRequired(checked);
    this[CreateLeadFormFields.managerId].changeRequired(!checked);

    this[CreateLeadFormFields.subAgentFullName].resetError();
    this[CreateLeadFormFields.subAgentPhone].resetError();
  }

  changeProject(value: number): void {
    this[CreateLeadFormFields.project].change(value);

    const placeTypes = this.expectedPlaceTypes;

    if (placeTypes) {
      this[CreateLeadFormFields.placeType].change(placeTypes.values().next().value);
    }
  }

  validate = (): boolean => {
    this.fieldsToValidate.forEach((field) => {
      this[field].validate();
    });

    if (this.isFieldError) {
      this.scrollToErrorField();
    }

    return this.isFieldError;
  };

  submit = async (): Promise<BaseResponse<undefined, { reason: ApiErrorCode | '' }>> => {
    const hasFormError = this.validate();

    if (hasFormError) {
      return {
        isError: true,
        data: {
          reason: '',
        },
      };
    }

    const data = this._toServerFormat();

    if (!data) {
      return {
        isError: true,
        data: {
          reason: '',
        },
      };
    }

    this.submitStage.loading();

    const response = await this._submitRequest.call<CreateLeadPayload>({
      data,
    });

    if (!response.isError) {
      this.submitStage.success();
      this.resultModalState.change({
        type: ResultType.success,
      });

      return {
        isError: false,
      };
    }

    this.submitStage.error();

    const responseData:
      | ApiErrorDataWithFields<keyof CreateLeadPayload>
      | ApiErrorData<ApiErrorCode.nonuniqueBid, CrmRejectionInfoServer>
      | null = response.data?.data || null;

    // Кейс, при котором сервер возвращает ошибку валидации полей формы
    if (
      (responseData?.code === ApiErrorCode.conflict || responseData?.code === ApiErrorCode.badRequest) &&
      responseData?.data?.fields
    ) {
      const fields: Partial<Record<keyof CreateLeadPayload, string>> = responseData.data.fields;

      Object.entries(fields).forEach(([key, error]) => {
        switch (key as keyof CreateLeadPayload) {
          case 'project': {
            this[CreateLeadFormFields.project].changeError(error);
            this[CreateLeadFormFields.project].scrollToField();

            break;
          }

          case 'place_type': {
            this[CreateLeadFormFields.placeType].changeError(error);
            this[CreateLeadFormFields.placeType].scrollToField();

            break;
          }

          default: {
            this._isServerErrorModel.change(true);
          }
        }
      });

      this.errorModalState.open();

      return {
        isError: true,
        data: {
          reason: ApiErrorCode.conflict,
        },
      };
    }

    // Кейс, когда созданная заявка не уникальна
    if (responseData?.code === ApiErrorCode.nonuniqueBid) {
      const crmRejectionInfo = responseData.data;

      if (!crmRejectionInfo) {
        this.resultModalState.change({
          type: ResultType.refused,
        });

        return {
          isError: true,
          data: {
            reason: ApiErrorCode.nonuniqueBid,
          },
        };
      }

      this.resultModalState.change({
        type: ResultType.refusedWithInfo,
        data: {
          crmRejectionInfo: LeadModel.normalizeCrmRejectionInfo(crmRejectionInfo),
        },
      });

      return {
        isError: true,
        data: {
          reason: ApiErrorCode.nonuniqueBid,
        },
      };
    }

    this._isServerErrorModel.change(true);

    return {
      isError: true,
      data: {
        reason: '',
      },
    };
  };

  resetServerError(): void {
    this._isServerErrorModel.change(false);
  }

  reset(): void {
    [...this.fieldsToValidate, CreateLeadFormFields.clientLastName].forEach((field) => {
      this[field].reset();
    });
    this[CreateLeadFormFields.isSubAgent].onChange(false, 'isSubAgent');
    this._setDefaultValues();
    this.resetServerError();
  }

  destroy(): void {
    super.destroy();
    this.reset();
  }
}
