export enum BreakpointEnum {
  xs = 'xs',
  sm = 'sm',
  xm = 'xm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const BREAKPOINTS: Record<BreakpointEnum, number> = {
  xs: 320,
  sm: 768,
  xm: 1024,
  md: 1280,
  lg: 1440,
  xl: 1920,
};
