import * as React from 'react';

import { useStrictContext } from '@kts-front/hooks';

import { NotificationListStore } from './NotificationListStore';

export const NotificationListStoreContext = React.createContext<NotificationListStore | null>(null);

export const useNotificationListStore = () => {
  const context = useStrictContext({
    context: NotificationListStoreContext,
    message: 'NotificationListStoreContext was not provided',
  });

  return context;
};
