import * as React from 'react';

import Typo, { TypoView, TypoWeight } from '@/components/Typo';

import s from './CardField.module.scss';

type Props = {
  title: string;
  value: React.ReactNode;
};
const EMPTY_CONTENT = '—';

const CardField: React.FC<Props> = ({ title, value }: Props) => {
  return (
    <div className={s.card}>
      <Typo view={TypoView.caption} color="grey-main" weight={TypoWeight.medium} className={s.card__title}>
        {title}
      </Typo>
      <Typo view={TypoView.text}>{value || EMPTY_CONTENT}</Typo>
    </div>
  );
};

export default React.memo(CardField);
