import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import IconButton from '@/components/IconButton';
import Typo, { TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';
import { useDragModalBottom } from '@/utils/useDragModalBottom';
import { useIsPWAOnIOS } from '@/utils/useIsPWA';

import ErrorNotice, { ErrorNoticeProps } from '../ErrorNotice/ErrorNotice';

import s from './RightWindow.module.scss';

export type RightWindowProps = React.PropsWithChildren<{
  className?: string;
  contentClassName?: string;
  showCloseButton?: boolean;
  onClose: VoidFunction;
  primaryButtonDisabled?: boolean;
  primaryButtonVisuallyDisabled?: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonOnClick?: VoidFunction;
  primaryButtonText?: string;
  header?: React.ReactNode;
  withBackdrop?: boolean;
  isScrollable?: boolean;
}> &
  ErrorNoticeProps;

const RightWindow: React.FC<RightWindowProps> = ({
  children,
  className,
  contentClassName,
  showCloseButton = true,
  onClose,
  primaryButtonDisabled,
  primaryButtonVisuallyDisabled,
  primaryButtonLoading,
  primaryButtonOnClick,
  primaryButtonText,
  header,
  withBackdrop = true,
  error,
  onCloseError,
  isScrollable = true,
}) => {
  const { isPhone } = useRootStore().uiStore;
  const isPWAOnIOS = useIsPWAOnIOS();

  const { dragModalBottom, swipeY, isDragging, headerRef } = useDragModalBottom(onClose, isScrollable);

  const modalCls = cn(s.content, withBackdrop && s.content_shadowNone, className, isPWAOnIOS && s.content_pwaIOS);

  return (
    <div
      className={modalCls}
      style={{
        transform: `translateY(${swipeY}px)`,
        transition: isDragging ? 'none' : 'transform 0.3s ease',
      }}
      {...dragModalBottom()}
    >
      {header && isPhone && (
        <div ref={headerRef} className={s.content_header}>
          {typeof header === 'string' ? (
            <Typo className={s['content_header-title']} view={TypoView.h2}>
              {header}
            </Typo>
          ) : (
            header
          )}
        </div>
      )}
      <div className={cn(s.content_children, contentClassName)}>
        {header && !isPhone && (
          <Typo className={s.content_title} view={TypoView.h2}>
            {header}
          </Typo>
        )}
        {children}
      </div>
      {error && (
        <ErrorNotice
          className={cn(s['content__error-notice'], primaryButtonText && s['content__error-notice_with-bottom-button'])}
          error={error}
          onCloseError={onCloseError}
        />
      )}
      {primaryButtonText && (
        <div className={s.content_buttons}>
          <Button
            disabled={primaryButtonDisabled}
            isLoading={primaryButtonLoading}
            visuallyDisabled={primaryButtonVisuallyDisabled}
            view={ButtonType.primary}
            size={ButtonSize.large}
            element="button"
            full
            onClick={primaryButtonOnClick}
          >
            {primaryButtonText}
          </Button>
        </div>
      )}
      {showCloseButton && (
        <IconButton
          className={s.content_closeIcon}
          iconName="IconClose"
          onClick={onClose}
          size={44}
          color={withBackdrop && !isPhone ? 'white' : 'grey-main'}
          aria-label="закрыть"
        />
      )}
    </div>
  );
};

export default observer(RightWindow);
