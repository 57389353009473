import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { UserRole, UserServer } from '@/entities/user';
import { adminUserRoleOptions, regularUserOptions } from '@/entities/user/config';
import { EmployeeModel } from '@/models/EmployeeModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { UserModel } from '@/models/UserModel';
import { SelectOption } from '@/types/values';

import { IRootStore } from '../RootStore';

type PrivateFields = '_userModel';

export interface IUserStore {
  user: UserModel | null;
  authorized: boolean;
  authLoadingStage: LoadingStageModel;
  logoutStage: LoadingStageModel;
  authorize(): Promise<void>;
  logout(): Promise<BaseResponse>;
  isAdmin: boolean;
  isBlockedUser: boolean;
  userRoleOptions: SelectOption<UserRole>[];
  getCanManageEmployee(employee: EmployeeModel): boolean;
}

type Params = {
  rootStore: IRootStore;
};

export class UserStore implements IUserStore {
  private _userModel: UserModel | null = null;

  private _userRequest = apiStore.createRequest<UserServer>({
    method: 'GET',
    url: apiUrls.user.current,
  });
  private _userLogoutRequest = apiStore.createRequest<UserServer>({ method: 'POST', url: apiUrls.user.logout });

  readonly authLoadingStage = new LoadingStageModel();
  readonly logoutStage = new LoadingStageModel();
  private readonly _rootStore: IRootStore;

  constructor({ rootStore }: Params) {
    makeObservable<this, PrivateFields>(this, {
      _userModel: observable.ref,
      user: computed,
      isAdmin: computed,
      authorized: computed,
      authorize: action,
      logout: action.bound,
    });

    this._rootStore = rootStore;
  }

  get user(): UserModel | null {
    return this._userModel;
  }

  get authorized(): boolean {
    return Boolean(this.user);
  }

  get isAdmin(): boolean {
    return (
      this._userModel !== null &&
      (this._userModel.role === UserRole.director || this._userModel.role === UserRole.fixator)
    );
  }

  get isDirector(): boolean {
    return this._userModel?.role === UserRole.director;
  }

  getCanManageEmployee(employee: EmployeeModel): boolean {
    if (!this._userModel || this.isBlockedUser) {
      return false;
    }

    // Пользователи не могут менять роли самих себя
    if (employee.id === this._userModel.id) {
      return false;
    }

    // Менеджер не может управлять сотрудниками
    if (this._userModel.role === UserRole.manager) {
      return false;
    }

    // Фиксатор может управлять только менеджерами
    if (employee.role.value !== UserRole.manager && this._userModel.role === UserRole.fixator) {
      return false;
    }

    // Руководитель не может управлять руководителями
    if (employee.role.value === UserRole.director && this._userModel.role === UserRole.director) {
      return false;
    }

    return true;
  }

  get userRoleOptions(): SelectOption<UserRole>[] {
    return this.isDirector ? adminUserRoleOptions : regularUserOptions;
  }

  async authorize(): Promise<void> {
    this.authLoadingStage.loading();

    const response = await this._userRequest.call();

    if (response.isError) {
      this.authLoadingStage.error();

      return;
    }

    runInAction(() => {
      this._userModel = UserModel.fromJson(response.data);
      this.authLoadingStage.success();
    });
  }

  get isBlockedUser(): boolean {
    return this._userModel?.isBlocked ?? false;
  }

  async logout(): Promise<BaseResponse> {
    if (this.logoutStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.logoutStage.loading();

    this._rootStore.pushSubcribeStore.unsubscribe();
    const response = await this._userLogoutRequest.call();

    if (response.isError) {
      this.logoutStage.error();

      return {
        isError: true,
      };
    }

    runInAction(() => {
      this._userModel = null;
      this.logoutStage.success();
    });

    return {
      isError: false,
    };
  }
}
