import { action, computed, makeObservable, observable } from 'mobx';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

type Params<V> = {
  checked?: boolean;
} & BaseFieldModelParams<V>;

export class CheckboxModel<V extends string | number> extends BaseFieldModel<V> {
  checked: boolean;

  constructor({ checked = false, ...params }: Params<V>) {
    super(params);

    makeObservable(this, {
      checked: observable,
      props: computed,
      onChange: action.bound,
    });

    this.checked = checked;
  }

  onChange(checked: boolean, value: V): void {
    this.checked = checked;

    super.change(value);
  }

  get props() {
    return {
      checked: this.checked,
      value: this._value,
      onChange: this.onChange,
      caption: this.caption,
      disabled: this.disabled,
      required: this.required,
    };
  }
}
