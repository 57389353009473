import * as React from 'react';

import { useStrictContext } from '@kts-front/hooks';

import { CreateLeadFormStore } from './CreateLeadFormStore';

type CreateLeadFormStoreContextValue = CreateLeadFormStore;

export const CreateLeadFormStoreContext = React.createContext<CreateLeadFormStoreContextValue | null>(null);

export const useCreateLeadFormStore = () => {
  const context = useStrictContext({
    context: CreateLeadFormStoreContext,
    message: 'CreateLeadFormStoreContext was not provided',
  });

  return context;
};
