import * as React from 'react';

import { AlertKind } from '@/entities/alert';
import { RootStore } from '@/stores/global/RootStore';

export const useOfflineError = (store: RootStore) => {
  const alertKeyRef = React.useRef<string | null>(null);

  React.useEffect(() => {
    const offlineHandler = () => {
      alertKeyRef.current = store.alertsStore.addAlert({
        message: 'Нет интернета, подключитесь к другой сети или зайдите позже',
        autoRemove: false,
        kind: AlertKind.error,
      });
    };

    const onlineHandler = () => {
      if (!alertKeyRef.current) {
        return;
      }

      store.alertsStore.removeAlert(alertKeyRef.current);
      alertKeyRef.current = null;
    };

    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);

    if (!window.navigator.onLine) {
      offlineHandler();
    }

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
    };
  }, []);
};
