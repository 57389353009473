import { RouteSegment } from './constants';

export const routerUrls = {
  root: '/',
  main: {
    mask: `/${RouteSegment.main}`,
    create: () => `/${RouteSegment.main}`,
  },
  leadList: {
    mask: `/${RouteSegment.leads}`,
    create: () => `/${RouteSegment.leads}`,
  },
  employeeList: {
    mask: `/${RouteSegment.employees}`,
    create: () => `/${RouteSegment.employees}`,
  },
};
