import DOMPurify from 'dompurify';

import { IPlaceType, PlaceTypeServer } from '@/entities/dictionaries';
export class PlaceTypeModel implements IPlaceType {
  readonly id;
  readonly label;
  readonly logo;

  constructor({ id, label, logo }: IPlaceType) {
    this.id = id;
    this.label = label;
    this.logo = logo;
  }

  static fromJson({ name, slug, logo }: PlaceTypeServer): PlaceTypeModel {
    return new PlaceTypeModel({
      id: slug,
      label: name,
      logo: logo ? DOMPurify.sanitize(logo) : null,
    });
  }
}
