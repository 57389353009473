import * as React from 'react';
import { LinkProps as RouterLinkProps, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';

export type AnchorProps = {
  element: 'a';
  href: string;
} & Omit<React.HTMLProps<HTMLAnchorElement>, 'value' | 'className' | 'onClick' | 'size'>;

export type LinkProps = {
  element: 'link';
} & Omit<RouterLinkProps, 'className' | 'onClick' | 'size'>;

export type NavLinkProps = {
  element: 'nav';
} & Omit<RouterNavLinkProps, 'className' | 'onClick' | 'size'>;

export type ButtonProps = {
  element: 'button';
} & Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'value' | 'className' | 'onClick' | 'size'
>;

export type ElementProps = {
  element?: React.ElementType;
} & Omit<React.HTMLProps<HTMLElement>, 'value' | 'className' | 'onClick' | 'size'>;

export type ValueProp = string | number | boolean | Record<string, any>;

export type ElementSpecificProps = ElementProps | AnchorProps | LinkProps | NavLinkProps | ButtonProps;

export type ClickedProps<V extends ValueProp> = {
  value?: V;
  onClickWithValue?: (value: V) => void;
  onClick?: VoidFunction;
} & ElementSpecificProps;

export const isLinkProps = (props: ElementSpecificProps): props is LinkProps => props.element === 'link';

export const isNavLinkProps = (props: ElementSpecificProps): props is NavLinkProps => props.element === 'nav';

export const isAnchorProps = (props: ElementSpecificProps): props is AnchorProps => props.element === 'a';

export const isButtonProps = (props: ElementSpecificProps): props is ButtonProps => props.element === 'button';
