import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import { ILead, LeadStatus } from '@/entities/lead';
import { useRootStore } from '@/stores/global/RootStore';

import CreateLeadModal from '../CreateLeadModal';

import s from './LeadActions.module.scss';

type Props = {
  item: ILead;
  className?: string;
  buttonClassName?: string;
  buttonView?: ButtonType;
  buttonSize?: ButtonSize;
  showShortText?: boolean;
  refetch?: VoidFunction;
};

const LeadActions: React.FC<Props> = ({
  item,
  className,
  buttonView = ButtonType.primary,
  buttonSize = ButtonSize.xs,
  buttonClassName,
  showShortText = false,
  refetch,
}) => {
  const {
    modalsStore: { meetingNoticeModal },
  } = useRootStore();
  const { opened: openedCreateLead, open: openCreateLead, close: closeCreateLead } = useToggleState();

  const buttonProps = React.useMemo(
    () => ({
      className: cn(s.actions_button, buttonClassName),
      size: buttonSize,
      view: buttonView,
    }),
    [buttonClassName, buttonSize, buttonView],
  );

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <div className={cn(s.actions, className)} onClick={handleClick}>
        {item.status === LeadStatus.unique && (
          <>
            <Button {...buttonProps} element="button" onClick={meetingNoticeModal.open}>
              {showShortText ? 'Новая встреча' : 'Назначить встречу'}
            </Button>
          </>
        )}
        {item.status === LeadStatus.wasUnique && (
          <Button {...buttonProps} element="button" onClick={openCreateLead}>
            {showShortText ? 'Новая заявка' : 'Создать заявку'}
          </Button>
        )}
        <CreateLeadModal
          opened={openedCreateLead}
          onOpen={openCreateLead}
          onClose={closeCreateLead}
          client={item}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default observer(LeadActions);
