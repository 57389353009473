import cn from 'classnames';
import * as React from 'react';

import Typo, { TypoView } from '../Typo';

import s from './Counter.module.scss';

type Props = {
  count: number;
  className?: string;
};

const Counter: React.FC<Props> = ({ count, className }: Props) => {
  const { countValue, kind } = React.useMemo<{ countValue: string | number | null; kind: string }>(() => {
    if (count >= 10000) {
      return { countValue: null, kind: 'ten-thousands' };
    }

    if (count >= 1000) {
      return { countValue: count.toString()[0] + ' тыс', kind: 'thousands' };
    }

    if (count >= 100) {
      return { countValue: count, kind: 'hundreds' };
    }

    if (count >= 10) {
      return { countValue: count, kind: 'tens' };
    }

    return { countValue: count, kind: '' };
  }, [count]);

  return (
    <Typo view={TypoView.caption} color="white" className={cn(s.counter, className, s[`counter_${kind}`])}>
      {countValue}
    </Typo>
  );
};

export default React.memo(Counter);
