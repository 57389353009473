import { nanoid } from 'nanoid';

import { type Alert, AlertKind } from '@/entities/alert';
import { ListModel } from '@/models/ListModel';

import { AddAlertParams, IAlertsStore } from './types';

// Время показа нотификации, мс
const ALERT_VISIBLE_TIME = 3000;

export class AlertsStore implements IAlertsStore {
  readonly alerts: ListModel<Alert> = new ListModel<Alert>();

  addAlert = ({ message, autoRemove = true, kind = AlertKind.default }: AddAlertParams) => {
    const key = nanoid();
    this.alerts.addEntity({ key, entity: { id: key, content: message, kind } });

    if (autoRemove) {
      setTimeout(() => this.removeAlert(key), ALERT_VISIBLE_TIME);
    }

    return key;
  };

  removeAlert = (id: Alert['id']): void => {
    this.alerts.removeEntity(id);
  };
}
