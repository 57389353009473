import * as React from 'react';

import Confirm, { ConfirmProps } from './Confirm';
import Overlay, { OverlayProps } from './Overlay';
import RightWindow, { RightWindowProps } from './RightWindow';
import TopWindow, { TopWindowProps } from './TopWindow';
import { ModalVariant } from './types';

type VariantProps =
  | ({
      variant: ModalVariant.Confirm;
    } & ConfirmProps)
  | ({
      variant: ModalVariant.RightWindow;
    } & RightWindowProps)
  | ({
      variant: ModalVariant.TopWindow;
    } & TopWindowProps);

export type ModalProps = OverlayProps & VariantProps;

const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <Overlay {...props}>
      {props.variant === ModalVariant.Confirm && <Confirm {...props}>{children}</Confirm>}
      {props.variant === ModalVariant.RightWindow && <RightWindow {...props}>{children}</RightWindow>}
      {props.variant === ModalVariant.TopWindow && <TopWindow {...props}>{children}</TopWindow>}
    </Overlay>
  );
};

export default Modal;
