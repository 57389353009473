import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import IconButton from '@/components/IconButton';
import Typo, { TypoElement, TypoView, TypoWeight } from '@/components/Typo';
import { type Alert as TAlert } from '@/entities/alert';
import { useRootStore } from '@/stores/global/RootStore';

import s from './Alert.module.scss';

type Props = {
  alert: TAlert;
  onRemove: () => void;
  className?: string;
};

const Alert = ({ alert: { kind, content }, onRemove, className }: Props): React.ReactElement<Props> => {
  const { isPhone } = useRootStore().uiStore;

  return (
    <div role="alert" className={cn(s.alert, className, s[`alert_${kind}`])}>
      <Typo
        className={s.alert__text}
        view={isPhone ? TypoView.textSmall : TypoView.text}
        element={TypoElement.h5}
        weight={TypoWeight.medium}
        color="white"
      >
        {content}
      </Typo>
      <IconButton
        className={s['alert__close-button']}
        iconName="IconClose"
        onClick={onRemove}
        size={24}
        color="white"
      />
    </div>
  );
};

export default observer(Alert);
