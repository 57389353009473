import { action, computed, makeObservable } from 'mobx';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

type Params<V> = BaseFieldModelParams<V>;

export class CheckboxGridModel<V extends string | number | null> extends BaseFieldModel<V> {
  constructor(params: Params<V>) {
    super(params);

    makeObservable(this, {
      props: computed,
      onChange: action.bound,
    });
  }

  onChange(value: V): void {
    super.change(value);
  }

  get props() {
    return {
      value: this._value,
      onChange: this.onChange,
      onBlur: this.validate,
      caption: this.caption,
      required: this.required,
      error: this.error,
    };
  }
}
