import { IProject, ProjectServer } from '@/entities/dictionaries';

export class ProjectModel implements IProject {
  readonly id;
  readonly label;
  readonly color;
  readonly placeTypes;

  constructor({ id, label, color, placeTypes }: IProject) {
    this.id = id;
    this.label = label;
    this.color = color;
    this.placeTypes = placeTypes;
  }

  static fromJson({ id, name, color, place_types }: ProjectServer): ProjectModel {
    return new ProjectModel({
      id,
      color,
      label: name,
      placeTypes: new Set(place_types?.map((placeType) => placeType.slug)),
    });
  }
}
