import { makeObservable, observable, runInAction } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { EmployeeServer, IEmployee } from '@/entities/employee';
import { UserRole } from '@/entities/user';
import { IRootStore } from '@/stores/global/RootStore';
import { getFullName } from '@/utils/getFullName';

import { LoadingStageModel } from './LoadingStageModel';
import { ValueModel } from './ValueModel';

type Params = {
  data: IEmployee;
  rootStore?: IRootStore;
};

export class EmployeeModel implements IEmployee {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string | null;
  readonly role: ValueModel<UserRole>;
  readonly label: string;

  readonly changeRoleStageModel = new LoadingStageModel();
  readonly changeBlockStatusModel = new LoadingStageModel();

  private _isBlocked: boolean;
  private readonly rootStore: IRootStore | null;

  private readonly changeRoleRequest = apiStore.createRequest<EmployeeServer>({
    method: 'PUT',
    url: apiUrls.employee.edit,
  });

  private readonly blockRequest = apiStore.createRequest<EmployeeServer>({
    method: 'POST',
    url: apiUrls.employee.block,
  });

  private readonly unblockRequest = apiStore.createRequest<EmployeeServer>({
    method: 'POST',
    url: apiUrls.employee.unblock,
  });

  constructor({ data, rootStore }: Params) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.role = data.role;
    this._isBlocked = data.isBlocked;
    this.label = data.label;

    this.rootStore = rootStore ?? null;

    makeObservable<this, '_isBlocked'>(this, {
      _isBlocked: observable,
    });
  }

  get isBlocked(): boolean {
    return this._isBlocked;
  }

  getFullName(reversed?: boolean): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName, reversed });
  }

  async changeRole(newRole: UserRole): Promise<BaseResponse> {
    if (this.hasLoading) {
      return { isError: true };
    }

    const currentRole = this.role.value;
    this.role.change(newRole);

    this.changeRoleStageModel.loading();

    const response = await this.changeRoleRequest.call({
      data: {
        id: this.id,
        role: newRole,
      },
    });

    if (response.isError) {
      this.role.change(currentRole);
      this.changeRoleStageModel.error();
      this.rootStore?.alertsStore.addAlert({ message: 'Не удалось изменить роль' });

      return { isError: true };
    }

    this.rootStore?.alertsStore.addAlert({ message: 'Роль успешно изменена' });
    this.changeRoleStageModel.success();

    return { isError: false };
  }

  async changeBlockStatus(): Promise<BaseResponse> {
    if (this.changeBlockStatusModel.isLoading) {
      return { isError: true };
    }

    const blocked = this._isBlocked;

    this.changeBlockStatusModel.loading();

    const request = blocked ? this.unblockRequest : this.blockRequest;

    const response = await request.call({
      data: {
        id: this.id,
      },
    });

    if (response.isError) {
      this.changeBlockStatusModel.error();
      this.rootStore?.alertsStore.addAlert({
        message: `Не удалось ${blocked ? 'разблокировать' : 'заблокировать'} сотрудника`,
      });

      return { isError: true };
    }

    runInAction(() => {
      this._isBlocked = !blocked;
      this.changeBlockStatusModel.success();
      this.rootStore?.alertsStore.addAlert({
        message: `Сотрудник успешно ${this._isBlocked ? 'заблокирован' : 'разблокирован'}`,
      });
    });

    return { isError: false };
  }

  get hasLoading(): boolean {
    return this.changeBlockStatusModel.isLoading || this.changeRoleStageModel.isLoading;
  }

  static fromJson({ data, rootStore }: { data: EmployeeServer; rootStore?: IRootStore }): EmployeeModel {
    return new EmployeeModel({
      data: {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phone: data.phone,
        role: new ValueModel(data.role),
        isBlocked: Boolean(data.is_blocked),
        label: getFullName({ firstName: data.first_name, lastName: data.last_name }),
      },
      rootStore,
    });
  }
}
