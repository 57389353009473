import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ILead } from '@/entities/lead';
import { useRootStore } from '@/stores/global/RootStore';

import s from './LeadPlaceType.module.scss';

const LeadPlaceType: React.FC<{ item: ILead }> = ({ item }) => {
  const { dictionariesStore } = useRootStore();
  const placeType = dictionariesStore.placeTypes.getEntity(item.placeType);

  return (
    placeType && (
      <div className={s.wrapper}>
        {placeType.logo && <div className={s['icon-container']} dangerouslySetInnerHTML={{ __html: placeType.logo }} />}
        <span className={s.title}>{placeType.label}</span>
      </div>
    )
  );
};

export default observer(LeadPlaceType);
