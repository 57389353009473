import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon from '@/components/Icon';
import InfoGrid from '@/components/InfoGrid';
import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';
import { ResultModalState, ResultType } from '@/stores/local/CreateLeadFormStore';

import { contentPropsConfig } from './config';
import { useFormattedInfo } from './useFormattedInfo';

import s from './CreateLeadResultModal.module.scss';

type Props = {
  state: ResultModalState | null;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  withBackdrop?: boolean;
};

const CreateLeadResultModal: React.FC<Props> = ({ state, onSubmit, ...props }) => {
  const { isPhone } = useRootStore().uiStore;
  const stateType = !state ? ResultType.refused : state.type;
  const contentProps = contentPropsConfig[stateType];

  const data =
    state?.type === ResultType.refusedWithInfo || state?.type === ResultType.refusedWithInfoList ? state.data : null;
  const { leadInfo, managerInfo } = useFormattedInfo(data);

  const description =
    state?.type === ResultType.refusedWithInfo || state?.type === ResultType.refusedWithInfoList
      ? state.data.crmRejectionInfo?.message || contentProps.description
      : contentProps.description;

  return (
    <Modal
      variant={ModalVariant.RightWindow}
      primaryButtonText={onSubmit && contentProps.buttonText}
      primaryButtonOnClick={onSubmit}
      opened={Boolean(state)}
      ariaLabel={contentProps.title}
      isScrollable={false}
      {...props}
    >
      <div className={s.content}>
        <div className={s.content_result}>
          <Icon
            iconName={contentProps.iconName}
            height={contentProps.iconHeight}
            width={300}
            className={cn(stateType !== ResultType.success && s['content__refused-icon'])}
          />
          <Typo view={TypoView.h2} element={TypoElement.h2}>
            {contentProps.title}
          </Typo>
          <Typo view={isPhone ? TypoView.textSmall : TypoView.text}>{description}</Typo>
        </div>
        {leadInfo && <InfoGrid className={s.content_info} title="Данные заявки" info={leadInfo} />}
        {managerInfo && <InfoGrid className={s.content_info} title="За кем закреплена" info={managerInfo} />}
      </div>
    </Modal>
  );
};

export default observer(CreateLeadResultModal);
