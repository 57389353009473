import * as React from 'react';

import { throttle } from '@kts-front/utils';

/** Хук подписывается на скролл и выполняет колбеки при скролле */
export const useScroll = <T extends HTMLElement = HTMLDivElement>(
  refContainer: React.MutableRefObject<T | null>,
  callback: (elem: T) => void,
) => {
  React.useEffect(() => {
    const ref = refContainer.current;

    if (!ref) {
      return;
    }

    const handleScroll = throttle({
      func: () => {
        callback(ref);
      },
      timeout: 300,
    });

    ref.addEventListener('scroll', handleScroll);

    return () => ref.removeEventListener('scroll', handleScroll);
  }, [callback]);
};
