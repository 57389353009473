import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DialogContentProps } from '@/components/DialogStub';
import Select from '@/components/form/Select';
import { SelectHorizontalView } from '@/components/form/Select/SelectHorizontal';
import InfiniteList from '@/components/InfiniteList';
import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView } from '@/components/Typo';
import { NotificationModelType } from '@/models/NotificationModel';
import { useRootStore } from '@/stores/global/RootStore';
import { useNotificationListStore } from '@/stores/local/NotificationListStore';

import NotificationCard from './NotificationCard/NotificationCard';
import { NOTIFICATION_TYPE_OPTIONS } from './config';

import s from './NotificationListModal.module.scss';

const EMPTY_STATE: DialogContentProps = {
  text: 'Новых уведомлений нет',
};

const NotificationListModal: React.FC = () => {
  const store = useNotificationListStore();
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isSuccessList = store.list.loadingStage.isSuccess && store.list.length;

  const { isPhone } = useRootStore().uiStore;

  return (
    <Modal
      variant={ModalVariant.RightWindow}
      opened={store.modalState.isOpen}
      ariaLabel="Уведомления"
      withBackdrop
      onClose={store.modalState.close}
      contentClassName={s.modal}
      className={s.modal__wrapper}
      header={
        isPhone && (
          <>
            <Typo view={TypoView.h2} block className={s.modal__title}>
              Уведомления
            </Typo>
            <Select
              className={s.modal__select}
              multiple={false}
              onChange={store.type.change}
              options={NOTIFICATION_TYPE_OPTIONS}
              value={store.type.value}
              variant="horizontal"
              view={SelectHorizontalView.small}
            />
          </>
        )
      }
    >
      {!isPhone && (
        <>
          <Typo view={TypoView.h2} block className={s.modal__title}>
            Уведомления
          </Typo>
          <Select
            className={s.modal__select}
            multiple={false}
            onChange={store.type.change}
            options={NOTIFICATION_TYPE_OPTIONS}
            value={store.type.value}
            variant="horizontal"
            view={SelectHorizontalView.small}
          />
        </>
      )}

      <div ref={containerRef} className={cn(s.modal__content, isSuccessList && s.modal__content_filled)}>
        <InfiniteList<NotificationModelType>
          loadMore={store.loadMore}
          hasMore={store.hasMore}
          containerRef={containerRef}
          entities={store.list.items}
          ItemComponent={NotificationCard}
          loadingStage={store.list.loadingStage.value}
          refetch={store.refetch}
          resetFilters={store.resetFilters}
          hasSelectedFiltersOrSearch={store.hasSelectedFilters}
          emptyState={EMPTY_STATE}
        />
      </div>
    </Modal>
  );
};

export default observer(NotificationListModal);
