import { CrmRejectionInfo, CrmRejectionInfoServer, ILead, LeadServer, LeadStatus } from '@/entities/lead';
import { getFullName } from '@/utils/getFullName';

export class LeadModel implements ILead {
  readonly id;
  readonly comment;
  readonly managerId;
  readonly managerFullName;
  readonly fullName;
  readonly firstName;
  readonly lastName;
  readonly phone;
  readonly email;
  readonly placeType;
  readonly project;
  readonly status;
  readonly createdDate;
  readonly expiryDate;
  readonly subAgent;
  readonly crmRejectionInfo;

  constructor({
    id,
    comment,
    createdDate,
    expiryDate,
    managerId,
    managerFullName,
    fullName,
    firstName,
    lastName,
    phone,
    placeType,
    project,
    status,
    email,
    subAgent,
    crmRejectionInfo,
  }: ILead) {
    this.id = id;
    this.comment = comment;
    this.createdDate = createdDate;
    this.expiryDate = expiryDate;
    this.managerId = managerId;
    this.managerFullName = managerFullName;
    this.fullName = fullName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.email = email;
    this.placeType = placeType;
    this.project = project;
    this.status = status;
    this.subAgent = subAgent;
    this.crmRejectionInfo = crmRejectionInfo;
  }

  static fromJson(raw: LeadServer): LeadModel {
    const subAgent =
      raw.sub_agent?.name || raw.sub_agent?.phone
        ? {
            name: raw.sub_agent.name,
            phone: raw.sub_agent.phone,
          }
        : null;

    const crmRejectionInfo =
      raw.status === LeadStatus.nonUnique && raw.crm_rejection_info
        ? this.normalizeCrmRejectionInfo(raw.crm_rejection_info)
        : null;

    const phone = raw.client.phone.startsWith('+') ? raw.client.phone : `+${raw.client.phone}`;

    return new LeadModel({
      id: raw.id,
      comment: raw.client.comment,
      createdDate: raw.created_at,
      expiryDate: raw.hold_date,
      managerId: raw.manager.id,
      managerFullName: getFullName({ firstName: raw.manager.first_name, lastName: raw.manager.last_name }),
      fullName: getFullName({ firstName: raw.client.first_name, lastName: raw.client.last_name }),
      firstName: raw.client.first_name,
      lastName: raw.client.last_name,
      phone,
      email: raw.client.email ?? '',
      placeType: raw.place_type,
      project: raw.project.name,
      status: raw.status,
      subAgent,
      crmRejectionInfo,
    });
  }

  static normalizeCrmRejectionInfo(from: CrmRejectionInfoServer): CrmRejectionInfo {
    return {
      leadId: from.lead_id,
      manager: {
        name: from.manager?.name || null,
        phone: from.manager?.phone || null,
      },
      agency: {
        title: from.agency?.title || null,
      },
      message: from.message || null,
      holdDate: from.hold_date || null,
    };
  }
}
