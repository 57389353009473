import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Checkbox from '@/components/form/Checkbox';
import InputField from '@/components/form/InputField';
import PhoneField from '@/components/form/PhoneField';
import Select from '@/components/form/Select';
import { useCreateLeadFormStore } from '@/stores/local/CreateLeadFormStore';

import s from '../CreateLeadModal.module.scss';

const ManagerForm: React.FC = () => {
  const {
    dictionaries,
    hasSelectedSubAgent,
    isManagerRole,
    managerId,
    isSubAgent,
    changeIsSubAgent,
    subAgentFullName,
    subAgentPhone,
  } = useCreateLeadFormStore();

  if (isManagerRole) {
    return null;
  }

  return (
    <div className={s.form__block}>
      {!isSubAgent.props.checked && (
        <Select
          className={s.form__block_narrow}
          variant="vertical"
          multiple={false}
          options={dictionaries.employees.items}
          size="large"
          {...managerId.props}
        />
      )}
      {!hasSelectedSubAgent && (
        <>
          <Checkbox {...isSubAgent.props} onChange={changeIsSubAgent} />
          {isSubAgent.props.checked && (
            <div className={s.form__doubleField}>
              <InputField {...subAgentFullName.props} />
              <PhoneField {...subAgentPhone.props} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(ManagerForm);
