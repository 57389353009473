const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getEmployeePrefix = (endpoint: string): string => `/employee/${endpoint}`;
const getIntegrationPrefix = (endpoint: string): string => `/integration/${endpoint}`;
const getNotificationsPrefix = (endpoint: string): string => `/notifications/${endpoint}`;

export const apiUrls = {
  dictionaries: {
    projects: '/project/list',
    placeTypes: '/placetype/list',
  },
  sources: {
    list: '/source/list',
  },
  leads: {
    list: '/bid/list',
    create: '/bid/create',
    item: {
      get: (id: number) => `/bid/${id}`,
    },
  },
  user: {
    current: getUserPrefix('current'),
    logout: getUserPrefix('logout'),
  },
  registration: '/registration',
  employee: {
    list: getEmployeePrefix('list'),
    edit: getEmployeePrefix('edit'),
    block: getEmployeePrefix('block'),
    unblock: getEmployeePrefix('unblock'),
    create: getEmployeePrefix('create'),
  },
  integration: {
    ispring: getIntegrationPrefix('ispring/get_url'),
  },

  notifications: {
    list: getNotificationsPrefix('list'),
    getPublicKey: getNotificationsPrefix('get_public_key'),
    subscribe: getNotificationsPrefix('subscribe'),
    removeSubscription: getNotificationsPrefix('remove_subscription'),
  },
};
