import { useMemo } from 'react';

import { InfoItem } from '@/components/InfoGrid';
import { ILead } from '@/entities/lead';
import { Nullable } from '@/types/values';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';

export const useFormattedInfo = (client?: ILead) => {
  const clientInfo: Nullable<InfoItem[][]> = useMemo(() => {
    const info: InfoItem[][] = [];

    if (client) {
      info.push([
        {
          label: 'Имя',
          value: client.fullName,
        },
        {
          label: 'Телефон',
          value: formatPhoneNumber(client.phone),
        },
        {
          label: 'Email',
          value: client.email,
        },
      ]);

      info.push([
        {
          label: 'Комментарий',
          value: client.comment,
        },
      ]);
    }

    return info.length ? info : null;
  }, [client]);

  const subAgentInfo: Nullable<InfoItem[][]> = useMemo(() => {
    const info: InfoItem[][] = [];

    const subAgent = client?.subAgent;

    if (subAgent) {
      info.push([
        {
          label: 'ФИО',
          value: subAgent.name,
        },
        {
          label: 'Телефон',
          value: subAgent.phone ? formatPhoneNumber(subAgent.phone) : '',
        },
      ]);
    }

    return info.length ? info : null;
  }, [client?.subAgent]);

  return {
    clientInfo,
    subAgentInfo,
  };
};
