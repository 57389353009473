import { TypoView } from '../Typo';

import { ButtonSize } from './types';

export const textConfig: Record<ButtonSize, TypoView> = {
  [ButtonSize.large]: TypoView.buttonLarge,
  [ButtonSize.medium]: TypoView.buttonMedium,
  [ButtonSize.small]: TypoView.buttonSmall,
  [ButtonSize.xs]: TypoView.caption,
  [ButtonSize.custom]: TypoView.buttonMedium,
};
