import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import IconButton from '@/components/IconButton';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import s from './ErrorNotice.module.scss';

export type ErrorNoticeProps = {
  className?: string;
  error?: React.ReactNode;
  onCloseError?: () => void;
};

const ErrorNotice: React.FC<ErrorNoticeProps> = ({ className, error, onCloseError }) => {
  const { isPhone } = useRootStore().uiStore;

  return (
    <div className={cn(s.wrapper, className)}>
      <Typo weight={TypoWeight.medium} color="white" view={isPhone ? TypoView.caption : TypoView.text}>
        {error}
      </Typo>
      <IconButton iconName="IconClose" color="white" size={32} onClick={onCloseError} />
    </div>
  );
};

export default observer(ErrorNotice);
