import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import Typo, { TypoView } from '@/components/Typo';
import { routerUrls } from '@/configs/routes';
import { useRootStore } from '@/stores/global/RootStore';

import Logo from './Logo';
import MobileTitle from './MobileTitle';
import { MobilePageTitles, PageTitles } from './config';

import s from './PageTitle.module.scss';

type Props = {
  className?: string;
};

const PageTitle: React.FC<Props> = ({ className }) => {
  const { pathname } = useLocation();
  const section = '/' + (pathname.split('/')[1] || '');

  const { isPhone } = useRootStore().uiStore;

  return (
    <div className={cn(s.title, className)}>
      {isPhone && MobilePageTitles[section] ? (
        <MobileTitle title={MobilePageTitles[section]} />
      ) : (
        <>
          <Logo />
          <Typo className={s.title_caption} view={TypoView.caption} uppercase>
            {PageTitles[section] ?? PageTitles[routerUrls.main.mask]}
          </Typo>
        </>
      )}
    </div>
  );
};

export default observer(PageTitle);
