import { CrmRejectionInfo } from '@/entities/lead';
import { Nullable } from '@/types/values';

export enum ResultType {
  success = 'success',
  refused = 'refused',
  refusedWithInfo = 'refusedWithInfo',
  refusedWithInfoList = 'refusedWithInfoList',
}

export type Manager = {
  firstName: string;
  lastName: string;
  phone: string;
  agency?: string;
};

export type RefusedWithInfoData = {
  crmRejectionInfo: Nullable<CrmRejectionInfo>;
};

export type ResultModalState =
  | { type: ResultType.success }
  | { type: ResultType.refused }
  | {
      type: ResultType.refusedWithInfo | ResultType.refusedWithInfoList;
      data: RefusedWithInfoData;
    };
